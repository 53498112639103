import request from "@/utils/request";
import Resource from "@/api/resource";

class AsistenciaResource extends Resource {
    constructor() {
        super("asistencia");
    }

    cerrarAsistencia(id) {
        return request({
            url: "/" + this.uri + "/" + id + "/cerrar",
            method: "put"
        });
    }

    marca(id, marca) {
        return request({
            url: "/" + this.uri + "/" + id + "/marca",
            method: "put",
            data: marca
        });
    }

    justifica(id, resource) {
        return request({
            url: "/" + this.uri + "/" + id + "/justifica",
            method: "put",
            data: resource
        });
    }

    anulaJustifica(id, resource) {
        return request({
            url: "/" + this.uri + "/" + id + "/anulajustifica",
            method: "put",
            data: resource
        });
    }

    getListBetween(resource) {
        return request({
            url: "/" + this.uri + "/getlistbeetwen",
            method: "post",
            data: resource
        });
    }

    getListMonth(resource) {
        return request({
            url: "/" + this.uri + "/getlistmonth",
            method: "post",
            data: resource
        });
    }

    pdfListMonth(query) {
        return request({
            url: "/" + this.uri + "/pdflistmonth",
            method: "post",
            data: query,
            responseType: "arraybuffer"
        });
    }

    // pdfListBetween(query){
    //     return request({
    //         url: '/' + this.uri + '/pdflistbetween',
    //         method: "get",
    //         params: query,
    //     });
    // }

    //   permissions(id) {
    //     return request({
    //       url: '/' + this.uri + '/' + id + '/permissions',
    //       method: 'get',
    //     });
    //   }

    //   updatePermission(id, permissions) {
    //     return request({
    //       url: '/' + this.uri + '/' + id + '/permissions',
    //       method: 'put',
    //       data: permissions,
    //     });
    //   }
}

export { AsistenciaResource as default };
