import request from "@/utils/request";
import Resource from "@/api/resource";

class XAreaResource extends Resource {
    constructor() {
        super("xareas");
    }

    getJefeArea(id) {
        return request({
            url: "/" + this.uri + "/" + id + "/getjefearea",
            method: "get"
        });
    }
}

export { XAreaResource as default };
