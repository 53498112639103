import request from "@/utils/request";
import Resource from "@/api/resource";

class ExteriorResource extends Resource
{
    constructor()
    {
        super("exterior");
    }

    getprocedimientos(query) {
      return request({
        url: 'getProcedimientos',
        method: 'get',
        params: query,
      });
    }

    getmovimientos(query) {
        return request({
          url: 'getmovimientosExterior',
          method: 'get',
          params: query,
        });
      }

      getdetalles(id) {
        return request({
          url: `detalleSolicitud/${id}`,
          method: 'get',
        });
      }

      getformatos(id) {
        return request({
          url: `getFormatosexterior/${id}`,
          method: 'get',
        });
      }

      donwloadArchivo(file) {
        return request({
          url: `descargaFormato/${file}`,
          method: 'get',
        });
      }
}

export { ExteriorResource as default };