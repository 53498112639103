import request from "@/utils/request";
import Resource from "@/api/resource";

class OperadorResource extends Resource {
  constructor() {

    super('operador');
  }
  

}

export { OperadorResource as default };