<template>
    <div style="width:auto; padding: 5px 0;">
      <el-row>
        <el-col :span="24">
            <div class="grid-content bg-white">
                  <h3>Información de Trámites </h3>
            </div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <br>
        <el-tabs v-model="activeName" type="border-card" @tab-click="selectTab">
          <el-tab-pane label="Seguimiento de Trámites" name="primero">
            <template v-if="opcion == 1">
              <tramite-externo></tramite-externo>
            </template>
          </el-tab-pane>
          <el-tab-pane label="Listado de Procedimientos TUPA/TUSNE" name="segundo">
            <template v-if="opcion == 2">
              <tupa-externo></tupa-externo>
            </template>
          </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>

import Tramites from "./TramiteExteriorComponent";
import TupaExterior from "./TupaExteriorComponent";

export default {
    data() {
        return {
            activeName:'primero',
            opcion: 1,
            tramite: 0,
            size:'medium',
            boton:false,
        };
    },
    created() {
        
    },
    methods: {
      selectTab()
      {
        if(this.activeName=='primero')
        {
          this.opcion=1;
        }
        else
        {
          this.opcion=2;
        }
        
      }

        
    },
    mounted() {
    }
};
</script>

<style>
  .el-row {
    margin-bottom: 20px;
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
