import request from "@/utils/request";
import Resource from "@/api/resource";

class SolicitudResource extends Resource
{
    constructor()
    {
        super("solicitud");
    }

    getmovimientos(query) {
        return request({
          url: 'getmovimientos',
          method: 'get',
          params: query,
        });
      }

      getdetalles(id) {
        return request({
          url: `detalleSolicitud/${id}`,
          method: 'get',
        });
      }

      donwloadArchivo(file) {
        return request({
          url: `donwloadArchivo/${file}`,
          method: 'get',
        });
      }

      listarObservaciones(id)
      {
        return request({
          url: `listarObservaciones/${id}`,
          method: 'get',
        });
      }

      levantarObservacion(resource)
      {
        return request({
          url: 'levantarObservacion',
          method: 'post',
          data: resource,
        });
      }

      getRequisitosPendientes(id)
      {
        return request({
          url: 'getRequisitosPendientes/' + id,
          method: 'get'
        });
      }

      registrarRequisitosPendientes(resource)
      {
        return request({
          url: 'registrarRequisitosPendientes',
          method: 'post',
          data: resource,
        });
      }

      getHorarioAtencion() {
        return request({
            url: 'atencionExpedientes',
            method: 'get'
        })
      }

    showConfirmacion(movimiento_id) {
        return request({
            url:'showConfirmacion/' + movimiento_id,
            method: 'get'
        })
    }
    saveConfirmacion(resource)
    {
        return request({
            url: 'saveConfirmacion',
            method: 'post',
            data: resource,
        });
    }
    apelarRespuesta(resource)
    {
        return request({
            url: 'apelarRespuesta',
            method: 'post',
            data: resource,
        });
    }
    getMovimientoActivo(id) {
        return request({
            url: 'movimientosActivos/' + id,
            method: 'get'
        })
    }
}

export { SolicitudResource as default };
