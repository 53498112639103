import request from "@/utils/request";
import Resource from "@/api/resource";

class TobreroResource extends Resource {
    constructor() {
        super("tobrero");
    }

    getTobreroData(id) {
        return request({
            url: "/" + this.uri + "/" + id + "/gettobrerodata",
            method: "get",
        });
    }

    //   updatePermission(id, permissions) {
    //     return request({
    //       url: '/' + this.uri + '/' + id + '/permissions',
    //       method: 'put',
    //       data: permissions,
    //     });
    //   }
}

export { TobreroResource as default };
