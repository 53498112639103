<template>
<div class="app-container bg-white">
      <div class="app-container bg-white">
          <br>
          <el-row>
            <el-col :span="24">
                <div class="grid-content bg-white">
                      <h3>Trámites Tupa/Tusne</h3>
                </div>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row align="center">
            <el-col :span="24">
              <div>
                <el-form :inline="true" :model="form" ref="form" onsubmit="return false;">
                  <el-form-item label="Trámite">
                    <el-input type="text" v-model="form.procedimiento" placeholder="Descripción de Trámite"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="filtrarProcedimientos()">Buscar</el-button>
                    <el-button type="primary" icon="el-icon-refresh" @click="getProcedimientos()">Todos</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
          </el-row>
          <el-card class="box-card">
            <div class="col-12">
            <el-col :xs="24" :sm="22" :md="24" :lg="24" :xl="24">
              <el-table
                  :data="list"
                  v-loading="loading"
                  border
                  style="width: 100%">
                  <el-table-column label="Item" width="60%" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.index }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Descripción" align="rigth" min-width="200">
                      <template slot-scope="scope">
                          <span>{{ scope.row.denominacion }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Documentos" width="200" align="center">
                    <template slot-scope="scope">
                      <!-- <el-button
                        size="mini"
                        type="warning"
                        @click="obtenerRequisitos(scope.row.id)"
                        >Requisitos</el-button> -->
                      <el-button
                        size="mini"
                        type="warning"
                        @click="showFormatos(scope.row.id)"
                        >Requisitos y Formatos</el-button>
                    </template>
                  </el-table-column>
                  <el-table-column label="Acciones" width="100" align="center">
                    <template slot-scope="scope">
                      <el-button
                        v-if="scope.row.presentacionExpediente_id === 1"
                        size="mini"
                        type="danger"
                        @click="abrirFormulario(scope.row.id,scope.row.catalogoProced_id)"
                        >Nuevo</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
              </div>
              </el-card>
              <div class="box-card">
                    <pagination v-show="total>0"
                        :total="total"
                        :page.sync="query.page"
                        :limit.sync="query.limit"
                        @pagination="getProcedimientos" />
              </div>
              <div class="box-card">
                    <pagination v-show="total2>0"
                        :total="total2"
                        :page.sync="query2.page"
                        :limit.sync="query2.limit"
                        @pagination="filtrarProcedimientos" />
              </div>

              <el-dialog title="Requisitos y Formatos" :visible.sync="dialogVisible">
                  <el-card class="box-card">
                    <el-table
                      :data="formatos"
                      v-loading="loading"
                      border
                      style="width: 100%"
                      size="mini">
                      <el-table-column label="Item" width="60%">
                          <template slot-scope="scope">
                              <span>{{ scope.row.index }}</span>
                          </template>
                      </el-table-column>
                      <el-table-column label="Descripción" align="rigth">
                          <template slot-scope="scope">
                              <span>{{ scope.row.nombre }}</span>
                          </template>
                      </el-table-column>
                      <el-table-column label="Accion" width="100" align="center" v-on:click="false">
                      <template slot-scope="scope">
                          <el-button type="warning" size="mini" @click="descargararchivo(scope.row.urlArchivo)">
                            Descargar
                          </el-button>
                      </template>
                    </el-table-column>
                    </el-table>

                  </el-card>
              </el-dialog>
      </div>
  </div>
</template>
<script>

import Pagination from "@/components/Pagination";
import ProcedimientosResource from "@/api/procedimientos";

const procedimientosResource = new ProcedimientosResource();

export default {
  name:'Procedimientos',
   components: { Pagination },

  data(){

    return{

      tramite: 0,

      loading:false,
            list:[],
            formatos:[],

            query: {
                page: 1,
                limit: 10,
                buscar:"",
                },

            query2: {
                page: 1,
                limit: 10,
                buscar:"",
                },

            requery: {
                idprocedimiento:'',
                nombreprocedimiento:'',
                },

            total: 0,
            total2:0,

            form:
            {
              procedimiento:''
            },

      dialogVisible:false,

    };

  },

  created() {
      this.getProcedimientos();
      this.$parent.boton=false;
      },

  methods:
  {

   /* handleCurrentChange(val) {
        this.requery.current_page=val;
        console.log(`current page: ${val}`);
      },*/

   async getProcedimientos() {
        this.form.procedimiento="";
        this.total2=0;
        const { limit, page } = this.query; // para paginacion
        this.query.buscar='';
        this.loading = true;
        const { data, meta } = await procedimientosResource.list(this.query);
        this.list = data;
        this.list.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1; // para item nro de filas
        });
        this.total = meta.total;
        this.loading=false;
      },

     async filtrarProcedimientos() {

       if(this.form.procedimiento!=''){
        const { limit, page } = this.query2; // para paginacion
        this.query2.buscar=this.form.procedimiento;
        this.loading = true;
        const { data, meta } = await procedimientosResource.list(this.query2);
        this.list = data;
        this.list.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1; // para item nro de filas
        });
        this.total=0;
        this.total2 = meta.total;
        this.loading=false;
       }
       else
       {
         this.$message({
          message: "Ingrese descripción del procedimiento a buscar",
          type: "info",
          duration: 1 * 3000
        });
       }
      },

   obtenerRequisitos(idprocedimiento) {

       //alert(idprocedimiento);
       //alert('hola');
        //const { limit, page } = this.requery;
        //const { href }=await procedimientosResource.getRequisitos(idprocedimiento);
        //alert(href);
        window.open('requisitospdf/'+idprocedimiento,'_blank')
        //window.open(href,'_blank');
       // this.requisitoslist= data;
        //this.dialogVisible=true;
        //this.requisitoslist.forEach((element, index) => {
          //element['index'] = (page - 1) * limit + index + 1;

        //});
        //this.rtotal = meta.total;
        //this.requery.page=meta.last_page;


       // alert(this.requisitoslist);
        /*
        this.loading = true;
        const { data, meta } = await procedimientosResource.list(this.query);
        this.list = data;
        this.list.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1; // para item nro de filas
        });
        this.total = meta.total;
        this.loading=false;]*/
      },

      abrirFormulario(idproce,catproced)
      {
        this.$parent.tramite=idproce;
        this.$parent.catproced=catproced;
        this.$parent.boton=true;
        this.$parent.opcion=3;
      },

      showFormatos(idprocedimiento)
      {
        this.loading=true;
        procedimientosResource.getformatos(idprocedimiento).then(response=>{
          if(response.respuesta==0)
          {
            Swal.fire(
                      '¡Atención!',
                      response.mensaje,
                      'warning'
                  );
            this.loading=false;
          }
          else
          {

            this.formatos=response.formatos;
            this.formatos.forEach((element, index) => {
            element['index'] = index + 1; // para item nro de filas
            });

            this.dialogVisible=true;
            this.loading=false;
          }
        }).catch(error => {
              console.log(error);
              me.$message({
                message: "Ocurrió un error inesperado",
                type: 'error',
                duration: 5 * 1000
              });
            });

      },

      descargararchivo(file)
      {
        window.open('downloadFormatos/'+file,'_blank');
        // window.location.href='downloadFormatos/'+file;
      },

  },


}
</script>

<style>

</style>
