import request from '@/utils/request';

class ReciboResource {

    comprobar(query){
        return request({
          url: 'comprobarRecibo',
          method: 'get',
          params: query
        });
    }

}

export { ReciboResource as default };
