<template>
    <el-dialog title="Requisitos Pendientes"
        :visible.sync="visible"
        append-to-body
        @close="cerrarModal"
        :close-on-click-modal="false" :close-on-press-escape="false">
        <div style="margin-top: 1%;" v-loading="loading">
            <div slot="header" class="clearfix" style="margin-top: 5px">
                <el-col>
                    <span style="font-size:small"><strong>COD. EXPEDIENTE: </strong> {{ codigo }}</span>
                </el-col>
                <el-col>
                    <span style="font-size:small"> <strong>PROCEDIMIENTO: </strong> {{ tramite }}</span>
                </el-col>
            </div>
            <hr>
                <el-form
                    v-if="presentacion === 1"
                    ref="form"
                    label-width="120px"
                    :model="form"
                    :rules="rules"
                    :inline-message="true"
                    >
                    <el-form-item label="Descripción:" prop="descripcion">
                            <el-input
                                type="textarea"
                                :rows="2"
                                v-model="form.descripcion"
                                resize="none">
                            </el-input>
                    </el-form-item>
                    <el-form-item label="Documento:" prop="archivo">
                        <el-col :xs="24" :sm="16" :md="15" :lg="13" :xl="10">
                            <input type="file" id="archivo" @change="seleccionarArchivo" accept=".pdf" style="width:100%">
                        </el-col>
                    </el-form-item>
                    <el-col style="text-align:center">
                        <el-button  type="primary" size="small" @click="registrarRequisitosPendientes">Guardar</el-button>
                        <el-button  type="danger" @click="cerrarModal" size="small">Cancelar</el-button>
                    </el-col>
                </el-form>
                <el-col v-if="presentacion !== 1" style="text-align:center">
                    <span style="
                    font-size: 14px;
                    color: blue;">
                <strong>El Levantamiento de requisitos pendientes de este tipo de trámite debe ser de manera presencial</strong></span>
                </el-col>
            <hr>
            <div slot="header" class="clearfix" style="padding-bottom: 10px;">
                <span style="font-size:medium">Requisito Pendiente:</span>
            </div>
            <el-table
                id="requisitos"
                :data="requisitos"
                border
                size="mini"
                style="width: 100%;">
                <el-table-column label="Item" width="40" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.index }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Requisito" align="right" min-width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.denominacion }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Concepto" align="right" min-width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.concepto }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Cantidad" align="right" min-width="30">
                    <template slot-scope="scope">
                        <span>{{ scope.row.cantidadNotificada }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Monto" align="right" min-width="40">
                    <template slot-scope="scope">
                        <span>S/. {{ scope.row.montoNotificado }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="Observación" align="right" min-width="40">
                    <template slot-scope="scope">
                        <span>{{ scope.row.observacion }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </el-dialog>

</template>

<script>
import SolicitudResource from "@/api/solicitud";

const solicitudResource = new SolicitudResource();

export default {

    props: {
        expedienteId: 0,
        movimientoId: 0,
        codigo: '',
        tramite: '',
        presentacion: 0
    },

    data(){
        var checkarchivo = (rule, value, callback) => {
            if(this.presentacion === 1) {
                if(this.fsize==1)
                return callback(new Error('El archivo excede al peso máximo de 25 MB, por favor seleccione otro archivo'));
                else callback();
            } else callback();

        };
        return{

            visible: true,
            loading:false,
            form:
            {
                descripcion:'',
                archivo: '',
                archivosize: '',
                expediente_id:'',
                movimiento_id:'',
                checked:false,
                obs_opcion:0,
                requisitos:[],
            },

            rules: {
                descripcion: [{
                        required: true,
                        message: 'Por favor, ingrese descripción.',
                        trigger: 'blur'
                }],

                archivo: [{
                    validator: checkarchivo,
                    trigger: "none"
                }
                ]

            },
            estado_notificacion: 2,
            expediente_id:'',
            fsize:0,
            requisitos:[]

        }
    },
    created()
    {
        this.getRequisitos();
    },

    methods:
    {
        cerrarModal()
        {
            if(this.estado_notificacion == 1) Swal.fire(
                '¡Atención!',
                '¡Se ha registrado la recepción de esta notificación!',
                'warning'
            );
            // this.$parent.getTramites();
            // this.$parent.opcionModal = 0;
            this.$emit('actualiza')
            this.$parent.opcionM = 0;
            this.visible = false;
        },

        seleccionarArchivo(e)
		{
			let files = e.target.files;
			if(!files.length) return;
			this.creaArchivo(files[0]);
		},

		creaArchivo(file)
		{
            this.fsize=0;
            this.form.archivosize='';
			let reader = new FileReader();
			let me = this;
			reader.onload = (e) => {
				me.form.archivo = e.target.result;
			};
			this.form.archivosize=file.size;
            reader.readAsDataURL(file);
            if(file.size>25000000){this.fsize=1;}
        },

        registrarRequisitosPendientes()
        {
            //this.loading=true;
            if(this.form.archivo != '' && this.form.archivo != null)
            {
                this.$refs['form'].validate(valid => {
                    if(valid)
                    {
                        this.loading=true;
                        solicitudResource.registrarRequisitosPendientes(this.form).then(response=>{
                            if(response.resultado == 1)
                            {
                                this.estado_notificacion = 0
                                Swal.fire(
                                    '¡Éxito!',
                                    response.mensaje,
                                    'success'
                                );
                                this.$emit('actualiza')
                                this.$parent.opcionM = 0;
                                this.visible = false;
                                this.loading=false;
                            }
                            else
                            {
                                this.$message({
                                    message: response.mensaje,
                                    type: 'error',
                                    duration: 5 * 1000
                                });
                                this.loading=false;
                            }
                        }).catch(error => {
                            console.log(error);
                            this.$message({
                                message: "Ocurrió un error inesperado",
                                type: 'error',
                                duration: 5 * 1000
                            });
                            this.loading=false;
                         });
                    }
                });

            }
            else
            {
                Swal.fire(
                    '¡Atención!',
                    'Tiene que cargar un archivo.',
                    'warning'
                );
            }


        },

        getRequisitos()
        {
            this.loading=true;
            solicitudResource.getRequisitosPendientes(this.movimientoId).then(response => {
                this.requisitos= response.requisitos;
                this.estado_notificacion = response.respuesta;
                this.form.requisitos= this.requisitos;
                this.requisitos.forEach((element, index) => {
                    element['index'] =  index + 1; // para item nro de filas
                });
                this.form.expediente_id= this.expedienteId;
                this.form.movimiento_id= this.movimientoId;
                this.form.descripcion = '';
                this.loading= false;
            }).catch(error => {
                console.log(error);
                this.$message({
                    message: "Ocurrió un error inesperado",
                    type: 'error',
                    duration: 5 * 1000
                });
                this.loading=false;
            });

        }
    }

}
</script>

<style>
    #requisitos .cell{
        font-size: 12px;
    }
</style>
