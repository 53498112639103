import axios from "axios";
import { Message } from "element-ui";
//import { getToken, setToken } from "@/utils/auth";

// Create axios instance
const service = axios.create({
    baseURL: process.env.MIX_BASE_API,
    timeout: 1000 * 30 // Request timeout
});

// Request intercepter
// service.interceptors.request.use(
//     config => {
//         // const token = getToken();
//         // if (token) {
//         //     config.headers["Authorization"] = "Bearer " + getToken(); // Set JWT token
//         // }

//         return config;
//     },
//     error => {
//         // Do something with request error
//         console.log(error); // for debug
//         Promise.reject(error);
//     }
// );

// response pre-processing
service.interceptors.response.use(
    response => {
        if (response.headers.authorization) {
            //setToken(response.headers.authorization);
            response.data.token = response.headers.authorization;
        }

        return response.data;
    },
    error => {
        let message = error.message;
        const vdata = error.response.data;
        const verror = error.response.data.error;
        const verrors = error.response.data.errors;

        //  let errores = Object.values(error.response.data.errors);
        //  errores = errores.flat();
        //  let message = errores;
        // console.log("·········data·······");
        // console.log(error.response.data);
        // console.log("·········error··········");
        // console.log(error.response.data.error);
        // console.log("········errors·········");
        // console.log(error.response.data.errors);
        // console.log("·······················");
        if (vdata != undefined && verrors != undefined) {
            let errores = Object.values(error.response.data.errors);
            errores = errores.flat();
            message = message + ". " + errores;
        } else if (vdata != undefined && verror != undefined) {
            message = message + ". " + error.response.data.error;
        }

        Message({
            message: message,
            type: "error",
            duration: 5 * 1000
        });
        return Promise.reject(error);
    }
);

export default service;
