<template>
  <div style="width:auto; padding: 2px 0;">
    <div v-if="registrar == 0">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="card-title">Verificación de DNI (Consignar los datos como aparecen en el DNI)</span>
        </div>
        <div v-loading="registroLoading" class="form-container">
          <el-form
            ref="validarForm"
            :rules="rulesValidar"
            :model="datosDNI"
            label-position="right"
            label-width="35%"
            :inline-message="true"
          >
            <el-row>
              <el-form-item label-width="0">
                <el-col :md="12" style="text-align: center;"><el-radio v-model="datosDNI.tipo" label="p" @change="datosDNI.ruc = ''">Cuenta de persona natural</el-radio></el-col>
                <el-col :md="12"><el-radio v-model="datosDNI.tipo" label="e" @change="datosDNI.ruc = ''">Cuenta de persona jurídica</el-radio></el-col>
              </el-form-item>
            </el-row>
            <template v-if="datosDNI.tipo == 'e'">
              <el-form-item label="RUC" prop="ruc">
                <el-input v-model="datosDNI.ruc"></el-input>
              </el-form-item>
              <h5 style="text-align: center;">DATOS DEL REPRESENTANTE LEGAL O TITULAR</h5>
              <hr style="margin-top: 0;">
            </template>
            <el-form-item label="DNI" prop="dni">
              <el-input v-model="datosDNI.dni"></el-input>
            </el-form-item>
            <el-form-item label="Ap. Paterno" prop="paterno">
              <el-input v-model="datosDNI.paterno"></el-input>
            </el-form-item>
            <el-form-item label="Ap. Materno" prop="materno">
              <el-input v-model="datosDNI.materno"></el-input>
            </el-form-item>
            <el-form-item label="Nombres" prop="nombres">
              <el-input v-model="datosDNI.nombres"></el-input>
            </el-form-item>
            <el-form-item label="Dirección" prop="direccion">
                <el-popover
                    ref="popover"
                    placement="right"
                    width="250"
                    trigger="focus"
                    title="Ingrese la dirección tal como se muestra en el DNI"
                >
                <img src="img/direccionCE.png" />
                </el-popover>
              <el-input v-popover:popover v-model="datosDNI.direccion"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="validar" type="primary">Validar</el-button>
              <el-button @click="cancelar" type="danger">Cancelar</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
    <div v-else-if="registrar == 1">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="card-title">Completar Datos Generales</span>
        </div>
        <div v-loading="registroLoading" class="form-container">
          <el-form
          ref="CurrentPersona"
          :model="CurrentPersona"
          :rules="rules"
          label-position="right"
            label-width="35%"
            :inline-message="true">
            <template v-if="datosDNI.tipo == 'e'">
              <el-form-item label="RUC" prop="ruc">
                <el-input v-model="CurrentPersona.ruc" disabled/>
              </el-form-item>
              <el-form-item label="Razón Social" prop="razonSocial">
                <el-input v-model="CurrentPersona.razonSocial" disabled/>
              </el-form-item>
              <h5 style="text-align: center;">DATOS DEL REPRESENTANTE LEGAL O TITULAR</h5>
              <hr style="margin-top: 0;">
            </template>
            <el-form-item label="DNI" prop="docIdentidad">
              <el-input v-model="CurrentPersona.docIdentidad" disabled/>
            </el-form-item>
            <el-form-item label="Nombre" prop="nombre">
              <el-input v-model="CurrentPersona.nombre" :disabled="deshabilitar"/>
            </el-form-item>
            <el-form-item label="Apellido Paterno" prop="paterno">
              <el-input v-model="CurrentPersona.paterno" :disabled="deshabilitar"/>
            </el-form-item>
            <el-form-item label="Apellido Materno" prop="materno">
              <el-input v-model="CurrentPersona.materno" :disabled="deshabilitar"/>
            </el-form-item>
            <el-form-item v-if="datosDNI.tipo == 'p'" label="Sexo">
              <el-col>
                <el-select v-model="CurrentPersona.sexo" placeholder="Seleccione sexo" style="width: 100%;">
                <el-option v-for="item in listsexo"
                :key="item.id"
                :label="item.sexo"
                :value="item.id"
                ></el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <template v-if="datosDNI.tipo == 'e'">
              <h5 style="text-align: center;">DATOS DE LA PERSONA JURÍDICA</h5>
              <hr style="margin-top: 0;">
            </template>
            <el-form-item v-if="datosDNI.tipo == 'p'" label="Fecha de Nacimiento">
              <el-col>
                <el-date-picker format="dd/MM/yyyy" v-model="CurrentPersona.fecha_nac" style="width: 100%;"></el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="Correo" prop="email">
              <el-input type="email" v-model="CurrentPersona.email" />
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input type="input" v-model="CurrentPersona.password" show-password />
            </el-form-item>
            <el-form-item label="Confirmar Password" prop="cpassword">
              <el-input type="input" v-model="CurrentPersona.cpassword" show-password />
            </el-form-item>
            <el-form-item label="Celular" prop="operador">
              <el-col :span="11">
                <el-select v-model="CurrentPersona.operador"
                placeholder="Seleccione operador"
                filterable
                >
                <el-option v-for="item in listoperador"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"

                ></el-option>

                </el-select>
              </el-col>
              <el-col class="line" :span="2"></el-col>
              <el-col :span="11">
              <el-input v-model="CurrentPersona.numero"/>
              </el-col>
              </el-form-item>
            <el-form-item label="Ubigeo" prop="departamento">
              <el-col :span="8">
                <el-select v-model="CurrentPersona.departamento"
                placeholder="departamento"
                filterable
                :disabled="deshabilitar"
                      @change="getProvincia()"
                      >
                      <el-option v-for="item in listdepto"
                      :key="item.id"
                      :label="item.nombre"
                      :value="item.id"

                ></el-option>
                </el-select>
              </el-col>
              <el-col class="line" :span="2"></el-col>
              <el-col :span="8">
                <el-select v-model="CurrentPersona.provincia"
                placeholder="provinvia"
                prop="provincia"
                filterable
                :disabled="deshabilitar"
                @change="getDistrito()"
                      >
                      <el-option
                      v-for="item in listprov"
                      :key="item.id"
                      :label="item.nombre"
                      :value="item.id"></el-option>

                </el-select>
              </el-col>
              <el-col class="line" :span="2"></el-col>
              <el-col :span="8">
                <el-select v-model="CurrentPersona.distrito"
                prop="distrito"
                filterable
                placeholder="distrito"
                :disabled="deshabilitar">
                      <el-option
                      v-for="item in listdist"
                      :key="item.id"
                      :label="item.nombre"
                      :value="item.id"></el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item v-if="datosDNI.tipo == 'p'" label="Dirección" prop="direccionReniec">
              <el-input v-model="CurrentPersona.direccionReniec" :disabled="deshabilitar"/>
            </el-form-item>
            <el-form-item v-else-if="datosDNI.tipo == 'e'" label="Dirección" prop="direccionSunat">
              <el-input v-model="CurrentPersona.direccionSunat" :disabled="deshabilitar"/>
            </el-form-item>
            <el-form-item label="Dirección de Notificación">
              <el-input v-model="CurrentPersona.direccionNotifica"/>
              <p v-if="datosDNI.tipo == 'p'" style="color: #0660a2; margin: 2px; font-size: 10px; text-align:justify">
                (Opcional) De ser necesario puede agregar dirección para notificaciones</p>
            </el-form-item>
            <el-form-item label="" prop="checked">
               <el-checkbox v-model="CurrentPersona.checked" @change="CambioCheck()">Acepta recibir correos de notificación a esta cuenta de correo</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="validacion('CurrentPersona')">
              Aceptar
            </el-button>
            <el-button type="danger" @click="cancelar()">
              Cancelar
            </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
      <el-dialog
        title="Terminos y Condiciones"
        :visible.sync="dialogVisible"
        :before-close="handleClose">
            <div class="col-12 margin: 4% 10% 2%; text-align: justify;font-family: sans-serif">
            <p style="margin: 2px; font-size: 12px; text-align:justify">
            Conste por el presente documento el acuerdo de uso de la casilla electrónica de la Municipalidad Provincial de Cajamarca que asume el usuario quien manifiesta conocer su contenido ACEPTANDO expresamente la totalidad de las condiciones que se detallan a continuación:
            </p>
            <br>
            <p style="margin: 4px; font-size: 10px; text-align:justify">
            1)   El Usuario acepta haber recibido de manera gratuita su respectiva clave y la casilla electrónica al momento de la presentación de sus documentos las que tienen carácter de personal.
            </p>
            <p style="margin: 2px; font-size: 10px; text-align:justify">
            2)   El Usuario se compromete a no ceder ni transferir bajo ninguna modalidad el uso de la clave y casilla que se le asigne, siendo en todo caso único responsable del uso que terceras personas puedan darle.
            </p>
            <p style="margin: 2px; font-size: 10px; text-align:justify">
            3)   La casilla electrónica será utilizada exclusivamente para la revisión del expediente administrativo y las notificaciones que dieran lugar el procedimiento administrativo.
            </p>
            <p style="margin: 2px; font-size: 10px; text-align:justify">
            4)   El Usuario acepta que las notificaciones electrónicas surtirán efectos desde el siguiente día hábil de haber sido recepcionada.
            </p>
            <p style="margin: 2px; font-size: 10px; text-align:justify">
            5)   El Usuario acepta que se constituye responsabilidad exclusiva de su persona el omitir por cualquier circunstancia el abrir y revisar su casilla electrónica y tomar conocimiento oportuno de las notificaciones administrativas.
            </p>
            <p style="margin: 2px; font-size: 10px; text-align: justify">
            6)   El Usuario mediante escrito podrá solicitar de manera escrita la cancelación de su casilla electrónica cuando lo considere pertinente.
            </p>
            <br>
            <p style="margin: 2px; font-size: 12px; text-align: justify">
              Las Notificaciones Electrónicas son jurídicamente validas desde antes de la Pandemia, Art. 20 y siguientes del TUO de la Ley 27444
            </p>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="AceptarCondiciones()">Aceptar</el-button>
          </span>
      </el-dialog>
  </div>
</template>

<script>
import RegistroResource from '@/api/registro';
import UserResource from "@/api/user";
import PersonaResource from "@/api/persona";
import DepartamentoResource from "@/api/departamento";
import OperadorResource from "@/api/operador";
import SexoResource from "@/api/sexo";
import ProvinciaResource from "@/api/provincia";
import DistritoResource from "@/api/distrito";
import EntidadResource from "@/api/entidad"

const registroResource = new RegistroResource();
const personaResource= new PersonaResource();
const userResource = new UserResource();
const departamentoResource= new DepartamentoResource();
const operadorResource= new OperadorResource();
const sexoResource= new SexoResource();
const provinciaResource= new ProvinciaResource();
const distritoResource=new DistritoResource();
const entidadResource = new EntidadResource();

export default {
  name: "Registro",
  data() {
    var checkDNI = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Por favor, ingrese el número de DNI'));
      }
      else if(value.length != 8){
        return callback(new Error('El DNI debe tener 8 dígitos'))
      }
      else if (!Number.isInteger(parseInt(value))) {
        return callback(new Error('Por favor, ingrese sólo números'));
      }else{
        callback();
      }
    };
    var checkRuc = (rule, value, callback) => {
      if(this.datosDNI.tipo == 'e')
      {
        if((!value)) return callback(new Error('Por favor, ingrese el RUC de la persona jurídica'));
        else if(value.length != 11) return callback(new Error('El RUC debe tener 11 dígitos'));
        else if(!Number.isInteger(parseInt(value))) return callback(new Error('Por favor, ingrese sólo números'));
        else callback();
      }
      else callback();
    };
    //Franco
    var validatePass = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('Por favor, ingresar password'));
      } else {
        if (this.CurrentPersona.cpassword !== '') {
          this.$refs.CurrentPersona.validateField('cpassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('Por favor, ingresar nuevamente el password'));
      } else if (value !== this.CurrentPersona.password) {
        callback(new Error('Las contraseñas no coinciden'));
      } else {
        callback();
      }
    };
    var validateChecked = (rule, value, callback) => {
      if (value == false) {
        return callback(new Error('Por favor, acepte el evío de notificaciones a su correo electrónico'));
      } else {
        return callback();
      }
    };

    var validateDepartamento = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Por favor, seleccione Departamento'));
      }else if(this.CurrentPersona.provincia==''){
        return callback(new Error('Por favor, seleccione Provincia'));
      }else if(this.CurrentPersona.distrito==''){
        return callback(new Error('Por favor, seleccione Distrito'));
      }
      else{
        callback();
      }
    };

     var validateOperador = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('Por favor, seleccione Operador'));
      }else if(this.CurrentPersona.numero==''|| !Number.isInteger(parseInt(this.CurrentPersona.numero))){
        return callback(new Error('Por favor, Ingrese Número'));
      }
      else{
        callback();
      }
    };

    var checkDireccionNotifica = (rule, value, callback) => {
      if(this.datosDNI.tipo == 'e')
      {
        if(this.CurrentPersona.departamento != 'CAJAMARCA' || this.CurrentPersona.provincia != 'CAJAMARCA' || this.CurrentPersona.provincia != 'CAJAMARCA')
        {
          if(!value) return callback(new Error('Por favor ingrese una dirección de notificación en la ciudad de Cajamarca'));
          else callback();
        }
      }
      else callback();
    };

    return {
      registrar: 0,
      registroLoading: false,
      datosDNI: {
        'tipo': 'p', //tipo p = persona, e = entidad
        'ruc': '',
        'dni': '',
        'paterno': '',
        'materno': '',
        'nombres': '',
        'direccion': '',
      },
      deshabilitar: false,

      rulesValidar: {
        ruc: [
          {
            validator: checkRuc,
            trigger: "none"
          }
        ],
        dni: [
          {
            validator: checkDNI,
            trigger: "none"
          }
        ],
        paterno: [
          {
            required: true,
            message: "Por favor, ingrese el apellido paterno",
            trigger: "none"
          }
        ],
        materno: [
          {
            required: true,
            message: "Por favor, ingrese el apellido materno",
            trigger: "none"
          }
        ],
        nombres: [
          {
            required: true,
            message: "Por favor, ingrese los nombres",
            trigger: "none"
          }
        ],
        direccion: [
          {
            required: true,
            message: "Por favor, ingrese la dirección",
            trigger: "none"
          }
        ],
      },
      // Parte César
      query: {
        page: 1,
        limit: 10,
        buscar:"",
      },
      CurrentPersona: {
        docIdentidad: '',
        ruc: '',
        razonSocial: '',
        nombre:'',
        paterno:'',
        materno:'',
        email: '',
        password: '',
        sexo: '',
        fecha_nac:'',
        cpassword:'',
        numero:'',
        direccionReniec:'',
        direccionSunat: '',
        direccionNotifica:'',
        operador:'',
        departamento:'',
        distrito:'',
        provincia:'',
        checked: false
      },
      odepartamento:[],
      listdepto:null,
      listoperador:[],
      listsexo:null,
      listprov:null,
      listdist:null,
      dialogVisible:false,
      valor:0,

      rules:{

        docIdentidad: [
          { validator: checkDNI, trigger: "none"}
        ],
        nombre:[
          { required: true, message: 'Por favor ingrese nombre(s)', trigger: 'none' }
        ],
        paterno:[
          { required: true, message: 'Por favor ingrese apellidos', trigger: 'none' }
        ],
        materno: [
          {
            required: true,
            message: "Por favor, ingrese el apellido materno",
            trigger: "none"
          }
        ],

        email:[
          { required: true, message: 'Por favor ingrese dirección email', trigger: 'blur' },
          { type: 'email', message: 'Ingrese correctamente el email', trigger: 'blur' }
        ],
        password: [
          {required: true, validator: validatePass, trigger: 'blur' }
        ],
        cpassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],

        direccionReniec: [
          {
            required: true,
            message: "Por favor, ingrese dirección",
            trigger: "none"
          }
        ],
        direccionNotifica: [
          { required: true, validator: checkDireccionNotifica, trigger: "none" }
        ],

         operador: [
            { validator:validateOperador, trigger: 'change' }
          ],

        numero: [
            { required: true, message: 'Por Favor, ingrese número Telefonico ', trigger: 'none' }
          ],

        departamento: [
            { validator:validateDepartamento, required: true, trigger: 'none' }
          ],

        provincia: [
            { required: true, message: 'Por Favor, escoja una provincia ', trigger: 'none' }
          ],

        distrito: [
            { required: true, message: 'Por Favor, escoja un distrito ', trigger: 'none' }
          ],

         checked: [
            { validator: validateChecked, trigger: 'none'}
          ],
      },
    };
  },
  // computed: {
  //   isDisabled: function(){

  //   }
  // },
created() {
      this.getDepartamento();
      this.getOperador();
      this.getSexos();
    },

  methods: {

    cancelar(){
      window.location.href = "login";
    },
    validar(){
      let me = this;
      me.$refs["validarForm"].validate(valid => {
        if (valid) {
          me.registroLoading = true;
          registroResource
            .validar(me.datosDNI)
            .then(response => {
              if(response.resultado == 1)
              {
                me.registrar = 1;
                me.CurrentPersona = response.persona;
                me.deshabilitar = true;
              }
              else if (response.resultado == 2)
              {
                me.registrar = 1;
                me.CurrentPersona = response.persona;
                me.deshabilitar = false;
              }
              else if (response.resultado == 3)
              {
                me.registrar = 1;
                me.CurrentPersona = response.entidad;
                me.deshabilitar = true;
              }
              else if(response.resultado == 0)
              {
                this.$message({
                  message: response.mensaje,
                  type: 'error',
                  duration: 5 * 1000
                });
              }
              me.registroLoading = false;
            })
            .catch(error => {
              this.$message({
                message: "Ocurrió un error, por favor vuelva a intentar más tarde.",
                type: "error",
                duration: 5 * 1000
              });
              me.registroLoading = false;
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },

    validacion(formName) {
      this.$refs["CurrentPersona"].validate((valid) => {
        if (valid) {
          this.registroLoading = true;
          if(this.datosDNI.tipo == 'p') this.createPersona();
          else if(this.datosDNI.tipo == 'e') this.createEntidad();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    handleClose(done) {
        this.$confirm('Si cierra este diálogo no podrá usar los servicios de Casilla Electrónica de la MPC?')
          .then(_ => {
            this.CurrentPersona.checked=false;
            done();
          })
          .catch(_ => {});
      },

    CambioCheck()
    {
      if(this.CurrentPersona.checked==true){
         this.dialogVisible=true;
      }
    },

    AceptarCondiciones()
    {
      this.valor=1;
      this.dialogVisible=false;
    },


    async getProvincia()
    {
      const { limit, page } = this.query; // para paginacion
      if(this.CurrentPersona.departamento<10){
      this.query.buscar='0' + this.CurrentPersona.departamento;
      }else{
        this.query.buscar=this.CurrentPersona.departamento;
      }
      const { data, meta } = await provinciaResource.list(this.query);
      this.listprov = data;
    },

    async getDistrito()
    {
      const { limit, page } = this.query; // para paginacion
      if(this.CurrentPersona.provincia<1000){
      this.query.buscar='0' + this.CurrentPersona.provincia;
      }else{
        this.query.buscar=this.CurrentPersona.provincia;
      }
      const { data, meta } = await distritoResource.list(this.query);
      this.listdist = data;
    },

    async getDepartamento() {

      departamentoResource
        .list({})
        .then(response => {
          this.listdepto = response.data;
        })
        .catch(error => {
          console.log(error);
        });

    },

    async getOperador() {

      operadorResource
        .list({})
        .then(response => {
          this.listoperador = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    async getSexos() {

      sexoResource
        .list({})
        .then(response => {
          this.listsexo = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    createPersona() {
      if(this.valor==1)
      {
        if(this.CurrentPersona.direccionNotifica == null)
        {
          this.CurrentPersona.direccionNotifica=this.CurrentPersona.direccionReniec;
        }
          personaResource.store(this.CurrentPersona)
        .then(response => {
          this.createUser();
          /*
          this.$message({
            message: 'Ciudadano ' + this.CurrentPersona.nombre + ' se agrego correctamente',
            type: 'succes',
            duration: 5 * 1000,
          });

          this.CurrentPersona = {
            dni: '',
            email: '',
            password: '',
          };
          this.loading=false;
          //this.FormVisible = false;
          //this.getList();*/
        })
        .catch(error => {
          console.log(error);
          this.registroLoading=false;
        });
      }
      else
      {
        this.CurrentPersona.checked=false;
      }
    },
    createEntidad() {
      if(this.valor==1)
      {
        entidadResource.store(this.CurrentPersona)
        .then(response => {
          this.createUser();
        })
        .catch(error => {
          console.log(error);
          this.registroLoading=false;
        });
      }
      else
      {
        this.CurrentPersona.checked=false;
      }
    },

    createUser() {
      this.registroLoading=true;
      userResource.store(this.CurrentPersona)
      .then(response => {
          if(response.respuesta === 0)
          {
            this.$message({
            message: response.mensaje,
            type: 'error',
            duration: 6 * 1000,
            });
            this.registroLoading=false;
          }
          else
          {
              if(response.respuesta === 1)
              {
                this.$message({
                message: 'Estimado usuario se le ha enviado un correo de confirmación a la cuenta de correo: ' +
                this.CurrentPersona.email + ' una vez confirmada su cuenta podrá acceder al sistema',
                type: 'succes',
                duration: 6 * 1000,
                });
                this.CurrentPersona = {
                docIdentidad: '',
                nombre:'',
                paterno:'',
                materno:'',
                email: '',
                password: '',
                sexo: '',
                fecha_nac:'',
                cpassword:'',
                numero:'',
                direccionReniec:'',
                operador:'',
                departamento:'',
                distrito:'',
                provincia:'',
                };
                setTimeout("location.href='login'", 3000);
                this.CurrentPersona.checked=false;
            // this.registroLoading=false;
              }
            }
      })
      .catch(error => {
        console.log(error);
        this.registroLoading=false;
      });
    },
  }
};
</script>

<style>
.swal2-container {
    z-index: 10000;
}

.el-form-item {
    margin-bottom: 5px !important;
}
  .el-dialog{
    width: 90%;
    max-width: 600px;
  }
.el-popover {
    word-break: normal;
    text-align: center;
}
</style>

