import request from "@/utils/request";
import Resource from "@/api/resource";

class DistritoResource extends Resource {
  constructor() {

    super('distrito');
    
  }
  
}

export { DistritoResource as default };