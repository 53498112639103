<template>
    <el-dialog title="Confirmación de Atención"
        :visible.sync="visible"
        append-to-body
        @close="cerrarModal"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div style="margin-top: 1%; margin-bottom: 10%;" v-loading="loading">
            <hr>
            <el-form
                ref="form"
                label-width="120px"
                :model="form"
                :rules="rules"
                :inline-message="true"
            >
                <el-form-item label="Cód. Expediente: " style="margin-bottom: 0;">
                    <span>{{ codigoExpediente }}</span>
                </el-form-item>
                <el-form-item label="Procedimiento: " style="word-break: break-word;">
                    <span>{{ procedimiento }}</span>
                </el-form-item>
                <template v-if="presentacion === 1">
                    <el-form-item label="Respuesta:" prop="motivo">
                        <el-select
                            v-model="form.motivo"
                            placeholder="Seleccione una Respuesta"
                            style="width: 100%"
                            clearable
                            filterable
                        >
                        <el-option
                            v-for="item in motivos"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                        />
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="form.motivo === 2">
                        <el-checkbox v-model="form.apelar">APELAR RESULTADO DE LA ATENCION</el-checkbox>
                    </el-form-item>
                    <el-form-item v-if="form.apelar === true" label="Descripción:" prop="descripcion">
                        <el-input
                            type="textarea"
                            :rows="2"
                            v-model="form.descripcion"
                            resize="none">
                        </el-input>
                    </el-form-item>
                    <el-form-item v-if="form.apelar === true">
                        <span style="font-size: 12px;color: blue;"> <strong>Debe seleccionar un documento (FUT/Solicitud) en formato pdf para esta acción.</strong></span>
                    </el-form-item>
                    <el-form-item v-if="form.apelar === true" label="Documento:" prop="archivo">
                        <el-col :xs="24" :sm="16" :md="15" :lg="13" :xl="10">
                            <input type="file" id="archivo" @change="seleccionarArchivo" accept=".pdf" style="width:100%">
                        </el-col>
                    </el-form-item>
                    <hr>
                    <el-form-item style="margin-left: -120px;text-align:center">
                        <el-button  type="primary" size="small" @click="registrarMovimiento">Guardar</el-button>
                        <el-button  type="danger" @click="cerrarModal" size="small">Cancelar</el-button>
                    </el-form-item>

                </template>
            </el-form>
            <el-col v-if="presentacion !== 1" style="text-align:center; margin-bottom: 2%;">
                <span style="
                font-size: 14px;
                color: blue;">
            <strong>La Confirmación de este tipo de trámite debe ser de manera presencial</strong></span>
            </el-col>
        </div>
    </el-dialog>
</template>
<script>
import SolicitudResource from "@/api/solicitud";
const solicitudResource = new SolicitudResource();
export default {
  name: 'ConfirmarAtencion',
  props: {
      expediente_id: 0,
      movimientoId: 0,
      presentacion: 0
  },
  data() {
      var validaArchivo = (rule, value, callback) => {
        if(this.form.apelar ==true && this.form.archivo === null) return callback(new Error('Tiene que adjuntar un archivo de notificación'))
        else if (this.form.archivo.size > 25000000) return callback(new Error('El archivo excede al peso máximo de 25 MB, por favor seleccione otro archivo'))
        else if (this.type !== 'application/pdf') return callback(new Error('El archivo debe se de tipo PDF'))
        else callback()
      }
      var validaDescripcion = (rule,value,callback) => {
          if (this.form.apelar && this.form.descripcion === '') return callback(new Error('Debe ingresar una descripción'))
          else callback()
      }
    return {
        form: {
            expediente_id: 0,
            movimiento_id: 0,
            motivo:'',
            descripcion: '',
            archivo: null,
            archivosize: 0,
            apelar: false
        },
        rules: {
            descripcion: [{
                validator: validaDescripcion,
                trigger: 'none'
            }],

            motivo: [{
                required: true,
                message: 'Por favor, seleccione una respuesta',
                trigger: 'none'
            }],
            archivo: [{
                validator: validaArchivo,
                trigger: 'none'
            }],
        },
        fsize: 0,
        type: '',
        anio: '',
        codigoExpediente: '',
        procedimiento: '',
        loading: false,
        visible: false,
        motivos: [
            {
                id: 1,
                nombre: 'ES CONFORME'
            },
            {
                id: 2,
                nombre: 'NO ES CONFORME'
            }
        ]
    }
  },
  created() {},
  mounted() { this.show() },
  methods: {
      seleccionarArchivo(e)
      {
        let files = e.target.files;
        if(!files.length) return;
        this.creaArchivo(files[0]);
      },
      creaArchivo(file)
      {
        this.fsize = 0
        this.type = ''
        this.form.archivosize='';
        let reader = new FileReader();
        let me = this;
        reader.onload = (e) => {
            me.form.archivo = e.target.result;
        };
        this.form.archivosize=file.size;
        this.type = file.type;
        reader.readAsDataURL(file);
        if(file.size>25000000){this.fsize=1;}
      },
      show() {
          this.loading = true
          solicitudResource.showConfirmacion(this.movimientoId).then(response => {
              if(response.respuesta === 1) {
                this.form.expediente_id = this.expediente_id
                this.form.movimiento_id = this.movimientoId
                this.codigoExpediente = response.codigoExpediente
                this.anio = response.anio
                this.procedimiento = response.procedimiento
                this.visible = true
              } else if(response.respuesta === 0) {
                  Swal.fire('¡Advertencia!', 'No es posible realizar esta acción', 'warning')
                  this.$emit('actualiza');
                  this.$parent.opcionM = 0;
              }
              this.loading = false
          }).catch(error => {
              this.$message({
                dangerouslyUseHTMLString: true,
                message: '<strong>Error</strong> <br>' + error,
                type: 'error'
            });
            this.loading = false
            this.$parent.opcionM = 0;
          })
      },
      registrarMovimiento() {
          this.$refs['form'].validate(valid => {
              if(valid) {
                this.loading = true
                solicitudResource.saveConfirmacion(this.form).then(response => {
                    if(response.resultado === 1) {
                        Swal.fire(
                            '¡Éxito!',
                            response.mensaje,
                            'success'
                        );
                        this.$emit('actualiza')
                        this.$parent.opcionM = 0;
                        this.visible = false
                    } else if(response.resultado === 0) {
                        this.$message({
                            dangerouslyUseHTMLString: true,
                            message: '<strong>Error</strong> <br>' + response.mensaje,
                            type: 'warning'
                        });
                    }
                    this.loading = false
                }).catch(error => {
                    this.$message({
                        dangerouslyUseHTMLString: true,
                        message: '<strong>Error</strong> <br>' + error,
                        type: 'error'
                    });
                    this.loading = false
                })
              } else {
                  return false
              }
          })
      },
      cerrarModal() {
        this.motivo = ''
        this.form.descripcion = ''
        this.form.archivo = null
        this.$emit('actualiza')
        this.$parent.opcionM = 0;
        this.visible = false
      }
  }
}
</script>

<style>

</style>
