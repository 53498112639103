import request from "@/utils/request";
import Resource from "@/api/resource";

class DepartamentoResource extends Resource {
  constructor() {

    super('departamento');
  }
  

}

export { DepartamentoResource as default };