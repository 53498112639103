import request from '@/utils/request';

class RegistroResource {
  constructor(uri){
    this.uri = uri;
  }
  validar(resource){
    return request({
      url: 'validar',
      method: 'post',
      data: resource 
    });
  }
}

export { RegistroResource as default };