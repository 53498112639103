<template>
    <div style="width:auto; padding: 5px 0;">
      <br>
      <el-row>
      <el-col :span="24">
          <div class="grid-content bg-white">
                <h3>Cambio de Contraseña</h3>
          </div>
          <el-divider></el-divider>
      </el-col>
    </el-row>
    
        <el-row justify="center">
          <el-col :span="6"><div class="grid-content bg-white"></div></el-col>
            <el-col :xs="24" :sm="20" :md="16" :lg="10" :xl="8" :span="8">
              <el-card class="box-card">
                    <el-form :model="form" 
                    :rules="rules" 
                    ref="form"
                    label-position="right"
                    label-width="35%"
                    :inline-message="true">
                    <el-form-item label="Contraseña Anterior" prop="apassword">
                        <el-input type="password" v-model="form.apassword" autocomplete="off" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="Nueva contraseña" prop="password">
                        <el-input type="password" v-model="form.password" autocomplete="off" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="Confirme contraseña" prop="cpassword">
                        <el-input type="password" v-model="form.cpassword" autocomplete="off" show-password></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-password" @click="validarForm('form')">Cambiar Contraseña</el-button>
                    </el-form-item>
        
                    </el-form>
              </el-card>
            </el-col>
          <el-col :span="6"><div class="grid-content bg-white"></div></el-col>
        </el-row>
  <br>
   <br> 
    </div>
</template>

<script>
import UserResource from "@/api/user";
const userResource = new UserResource();

export default {
    name:'CambioPassword',
    props: { auth: Object },

    data(){

    var validatePassAnt = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('Por favor, ingresar password'));
      } else {
        callback();
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('Por favor, ingresar password'));
      } else {
        if (this.form.cpassword !== '') {
          this.$refs.form.validateField('cpassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('Por favor, ingresar nuevamente el password'));
      } else if (value !== this.form.password) {
        callback(new Error('Las contraseñas no coinciden'));
      } else {
        callback();
      }
    };

        return{

            form:{
               password:'',
               cpassword:'',
               apassword:'',
            },
            
            rules:
            {
                apassword: [
                    { validator: validatePassAnt, trigger: 'blur' }
                ],
                password: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                cpassword: [
                    { validator: validatePass2, trigger: 'blur' }
                ],
            },

            prueba:'',
        }

    },

    methods:
    {
        validarForm(formName) {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.CambiarPass()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      CambiarPass()
      {
          userResource.updatepass(this.form)
          .then(response => {
            this.$message({
              message: 'El password se actualizo correctamente',
              type: 'succes',
              duration: 5 * 1000,
            });
            this.form={
                password:'',
               cpassword:'',
               apassword:'',
            };
            
          }).catch(error => {
            console.log(error);
          });
      }

    }
    
};
</script>


<style>

</style>