import request from "@/utils/request";
import Resource from "@/api/resource";

class ProvinciaResource extends Resource {
  constructor() {

    super('provincia');
  }
  
}

export { ProvinciaResource as default };