<template>
    <div style="width:auto; padding: 5px 0;">
      <el-row>
        <el-col :span="24">
            <div class="grid-content bg-white">
                  <h3>Mesa de Partes Virtual Casilla Electrónica</h3>
            </div>
        </el-col>
      </el-row>
    <el-alert
        title="INFORMACIÓN"
        type="info"
        show-icon>
        <div style="text-align: justify;" v-html="alert"></div>
    </el-alert>
      <!-- <el-alert :title="alert" type="error" :closable="false" effect="dark"/> -->
      <el-divider></el-divider>
      <br>
        <el-row :gutter="5">
          <el-col :xs="24" :sm="6" :md="5" :lg="4" :xl="3">
            <el-button style="margin-bottom: 5px; width: 100%;" type="primary" @click="opcion=1" :disabled="boton">Mi casilla</el-button>
          </el-col>
          <el-col :xs="24" :sm="6" :md="5" :lg="4" :xl="3">
            <el-button style="margin-bottom: 5px; width: 100%;" type="success" @click="opcion=2" :disabled="boton">Trámites Tupa/tusne</el-button>
          </el-col>
          <el-col :xs="24" :sm="6" :md="5" :lg="4" :xl="3">
            <el-button style="margin-bottom: 5px; width: 100%;" type="warning" @click="opcion=3, tramite=otros, boton=true" :disabled="boton">Otros Trámites</el-button>
          </el-col>
      </el-row>

      <template v-if="opcion == 0">
        <principal :auth="auth"></principal>
      </template>
      <template v-if="opcion == 1">
        <micasilla></micasilla>
      </template>
      <template v-if="opcion == 2">
        <tramite></tramite>
      </template>
      <template v-if="opcion == 3">
        <formulario-tramite :procedimiento_id="tramite" :catproced_id="catproced"></formulario-tramite>
      </template>

    </div>
</template>

<script>
import Principal from "./PrincipalComponent";
import Tramites from "./TramitesTupaComponent";
import FormularioTramite from "./FormularioTramiteComponent";

import ProcedimientosResource from "@/api/procedimientos";
import SolicitudResource from "@/api/solicitud";

const procedimientosResource = new ProcedimientosResource();
const solicitudResource = new SolicitudResource();

export default {
    name: "Dashboard",
    props: { auth: Object },
    data() {
        return {
            opcion: 0,
            tramite: 0,
            catproced:999,
            otros:0,
            size:'medium',
            boton:false,
            alert: 'La <u><b>PRESENTACIÓN</b></u> de sus documentos se podrá realizar las 24 horas del día los 7 días de la semana. Los documentos ingresados a la Mesa de Partes Virtual entre las 00:00 horas hasta las 23:59 horas se consideran presentados dentro del mismo día hábil. Se considera que el documento ha sido presentado dentro del día hábil cuando la carga de dicho documento haya sido finalizada y enviada hasta las 23:59 horas del mismo día hábil. Cabe indicar que los documentos presentados los días sábados, domingos y feriados se considerarán presentados al siguiente día hábil.'
        };
    },
    created() {
      //this.getTamaño();
      this.getTramiteOtros();
      // this.getHorarioAtencion();
    },
    methods: {
      getTamaño(){

        if(window.innerWidth < 768){
          this.size='mini';
        }


      },

      getTramiteOtros()
      {
          procedimientosResource.getTramiteOtros().then(response=>{
              this.otros=response.id;
          }
          ).catch(error => {
              console.log(error);
              me.$message({
                message: "Ocurrió un error inesperado",
                type: 'error',
                duration: 5 * 1000
              });
            });
      },

      getHorarioAtencion()
      {
          solicitudResource.getHorarioAtencion().then(response => {
              if (response.resultado === 1) {
                  this.alert = response.mensaje;
              }
          }).catch(error => {
              console.log(error);
              me.$message({
                message: "Ocurrió un error inesperado",
                type: 'error',
                duration: 5 * 1000
              });
            });
      }

    },
    mounted() {
    }
};
</script>

<style>
  .el-row {
    margin-bottom: 20px;
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
