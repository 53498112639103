<template>
<div class="app-container bg-white">
      <div class="app-container bg-white">
          <br>
          <el-row>
            <el-col :span="24">
                <div class="grid-content bg-white">
                      <h3>Mi Casilla Electrónica</h3>
                </div>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row align="center">
            <el-col :span="24">
              <div>
                <el-form :inline="true" :model="form" ref="form" onsubmit="return false;" label-width="120px">
                  <el-form-item label="Nro. Expediente">
                    <el-input type="text" v-model="form.tramite" placeholder="Nro de Exped. / Descripción"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="buscarTramites()">Buscar</el-button>
                    <el-button type="primary" icon="el-icon-refresh" @click="getTramites()">Todos</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
          </el-row>
          <el-card style="padding-bottom: 20px;">
            <div class="col-12">
            <el-col :xs="24" :sm="22" :md="24" :lg="24" :xl="24">
              <el-table
                  ref="expediente"
                  :data="list"
                  v-loading="loading"
                  border
                  style="width: 100%"
                  size="small"
                  highlight-current-row
                  @cell-click="getmovimientos"
                  >
                  <el-table-column label="Item" width="40" align="center" prop="item">
                      <template slot-scope="scope">
                          <span>{{ scope.row.index }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Descripción" align="rigth" min-width="200">
                      <template slot-scope="scope">
                          <span>{{ scope.row.denominacion }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Código Expediente" width="100" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.codigoExpediente }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Solicitante" width="180" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.solicitante }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Estado" width="100" align="center">
                    <template slot-scope="scope">
                          <!-- <span>{{ scope.row.estado }}</span> -->
                        <template v-if="scope.row.data.length === 1">
                            <span v-if="scope.row.data[0].estado === 'ENVIADO'"> {{ (scope.row.data[0].motivo_id === 1 || scope.row.data[0].motivo_id === 5) ? 'ENVIADO' : scope.row.data[0].accion_id === 4 ? 'NOTIFICADO' : 'ADMITIDO' }}</span>
                            <span v-else>{{ scope.row.data[0].estado === 'PENDIENTE' ? (scope.row.data[0].motivo_id === 6 ? scope.row.data[0].estado + ' ADMITIDO' : scope.row.data[0].estado + ' OBSERVADO'): scope.row.data[0].estado }}</span>
                        </template>
                        <template v-else-if="scope.row.data.length > 1">
                            <el-row v-for="(item, index ) in scope.row.data" :key="item.id">
                                <span v-if="item.estado === 'ENVIADO'"> {{ item.accion_id === 4 ? (index + 1) + '. ' + 'NOTIFICADO' : (index + 1) + '. ' + 'ADMITIDO' }}</span>
                                <span v-else>
                                    {{ (index + 1) + '. ' + item.estado }}
                                </span>
                            </el-row>
                        </template>
                    </template>
                  </el-table-column>
                  <el-table-column label="Año" width="40" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.anio }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Fecha" width="80" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.fecha }}</span>
                      </template>
                  </el-table-column>
                  <!-- <el-table-column label="Observación" width="180" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.observacion }}</span>
                      </template>
                  </el-table-column> -->
                  <el-table-column label="Detalles" width="100" align="center" key="detalle">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="primary"
                        @click="getDetalles(scope.row.id)"
                        >Ver detalle</el-button>
                    </template>
                  </el-table-column>
                  <el-table-column label="Acciones" width="100" align="center" key="acciones">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        @click="abrirModal(scope.row.id, 1,scope.row.codigoExpediente,scope.row.denominacion,scope.row.descripcion)"
                        >Acciones</el-button>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="Notificaciones" width="120" align="center" v-on:click="false">
                    <template slot-scope="scope">
                    <el-col v-if="scope.row.estado === 'OBSERVADO'">
                        <el-button
                        v-if="(scope.row.accion_id === 4 && scope.row.motivo_id === 10 && scope.row.estado_id === 2) || (scope.row.accion_id === 3 && scope.row.motivo_id === 9 && scope.row.estado_id === 6)"
                        size="mini"
                        type="danger"
                        @click="abrirModal(scope.row.id, 1,scope.row.denominacion,scope.row.descripcion)"
                        >{{ scope.row.accion_id === 4 ? 'Ver' : 'Atender' }} Notificación</el-button>
                    </el-col>
                    <el-col v-else-if="scope.row.estado === 'NOTIFICADO'">
                        <el-col v-if="(scope.row.accion_id === 4 && scope.row.motivo_id === 12 && scope.row.estado_id === 2) || (scope.row.accion_id === 3 && scope.row.motivo_id === 9 && scope.row.estado_id === 6)">
                            <el-button
                            size="mini"
                            type="success"
                            @click="abrirModal(scope.row.id, 2,scope.row.denominacion,scope.row.descripcion)"
                            >{{ scope.row.accion_id === 4 ? 'Ver' : 'Atender' }} Notificación</el-button>

                        </el-col>
                        <el-col v-if="scope.row.accion_id === 4 && scope.row.motivo_id === 13 && scope.row.estado_id === 2">
                            <el-button
                            size="mini"
                            type="success"
                            @click="abrirModal(scope.row.id, 3,scope.row.denominacion,scope.row.descripcion)"
                            >Responder Notificación</el-button>
                        </el-col>
                    </el-col>
                    <el-col v-if="scope.row.accion_id === 5 && scope.row.motivo_id === 17 && scope.row.estado_id === 8">
                        <el-button
                        size="mini"
                        type="success"
                        @click="abrirModal(scope.row.id, 4,scope.row.denominacion,scope.row.descripcion)"
                        >Apelar Resultado</el-button>
                    </el-col>
                    </template>
                  </el-table-column> -->
                </el-table>
              </el-col>
              </div>
              </el-card>
              <div class="box-card">
                    <pagination v-show="total>0"
                        :total="total"
                        :page.sync="query.page"
                        :limit.sync="query.limit"
                        @pagination="getTramites" />
              </div>
              <div class="box-card">
                    <pagination v-show="total2>0"
                        :total="total2"
                        :page.sync="query2.page"
                        :limit.sync="query2.limit"
                        @pagination="buscarTramites" />
              </div>

      </div>
      <div class="app-container bg-white">
          <br>
          <el-row>
            <el-col :span="24">
                <div class="grid-content bg-white">
                      <h3>Movimientos</h3>
                </div>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-card style="padding-bottom: 20px; margin-bottom: 30px;">
            <div class="col-12">
            <el-col :xs="24" :sm="22" :md="24" :lg="24" :xl="24">
              <el-table
                  :data="movimientos"
                  v-loading="loadmov"
                  border
                  size="small"
                  style="width: 100%">
                  <el-table-column label="Item" width="60%" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.index }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Acción" align="rigth" min-width="100">
                      <template slot-scope="scope">
                          <span>{{ scope.row.nombre }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Fecha Movimiento" width="180" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.fecha }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Origen" width="200" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.origen }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Destino" width="200" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.destino }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Folios" width="60%" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.folios }}</span>
                      </template>
                  </el-table-column>
                </el-table>
              </el-col>
              </div>
              </el-card>

      </div>
      <div>
        <el-dialog title="Detalle Solicitud"
        v-loading="loaddet"
        :visible.sync="FormVisible"
        id="modalDetalle"
        >
          <el-card class="box-card" shadow="never" v-if="empresa!=1">
            <div slot="header" class="clearfix">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="20" :md="16" :lg="14" :xl="1" style="width:100%">
                <span style="font-size:medium; min-with:340px">Datos Ciudadano</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="getDetallepdf(ciudadano.exp_id)">Descargar PDF</el-button>
              </el-col>
            </el-row>
            </div>

            <div class="row d-none d-block d-lg-none">
              <el-form :model="ciudadano" ref="ciudadano" label-position="top" label-width="100px" size="mini">
                <el-form-item label="" style="font-weight: bold;">
                  <p style="font-weight: normal; margin-bottom: 0px; text-align:center">{{ciudadano.docIdentidad}}</p>
                </el-form-item>
                <el-form-item label="" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px; text-align:center">{{ciudadano.ciudadano}}</p>
                </el-form-item>
                <el-form-item label="" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px; text-align:center">{{ciudadano.correo}}</p>
                </el-form-item>
                <el-form-item label="" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px; text-align:center">{{ciudadano.telefono}}</p>
                </el-form-item>
                <el-form-item label="" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px; text-align:center">{{ciudadano.direccionNo}}</p>
                </el-form-item>
                </el-form>
            </div>


              <div class="box-center d-none d-lg-block">
              <el-form :model="ciudadano" ref="ciudadano" label-position="right" label-width="200px" size="mini">
                <el-form-item label="Documento de Identidad:" style="font-weight: bold;">
                  <el-col :xs="24">
                  <p style="font-weight: normal; margin-bottom: 0px; whidth:100%">{{ciudadano.docIdentidad}}</p>
                  </el-col>
                </el-form-item>
                <el-form-item label="Nombres y Apellidos:" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px;">{{ciudadano.ciudadano}}</p>
                </el-form-item>
                <el-form-item label="Correo de Notificación:" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px;">{{ciudadano.correo}}</p>
                </el-form-item>
                <el-form-item label="Teléfono de Notificación:" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px;">{{ciudadano.telefono}}</p>
                </el-form-item>
                <el-form-item label="Dirección de Notificación:" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px;">{{ciudadano.direccionNo}}</p>
                </el-form-item>
            </el-form>
            </div>
          </el-card>
          <div>
            <el-card class="box-card" shadow="never" v-if="empresa==1">
              <div slot="header" class="clearfix">
              <el-row :gutter="10">
                <el-col :xs="24" :sm="20" :md="16" :lg="14" :xl="1" style="width:100%">
                  <span style="font-size:medium; min-with:340px">Datos Empresa</span>
                  <el-button style="float: right; padding: 3px 0" type="text" @click="getDetallepdf(ciudadano.exp_id)">Descargar PDF</el-button>
                </el-col>
              </el-row>
              </div>

              <div class="row d-none d-block d-lg-none">
                <el-form :model="ciudadano" ref="ciudadano" label-position="top" label-width="200px" size="mini">
                  <el-form-item label="" style="font-weight: bold;">
                    <p style="font-weight: normal; text-align:center">{{ciudadano.ruc}}</p>
                  </el-form-item>
                  <el-form-item label="" style="font-weight: bold;">
                    <p style="font-weight: normal; text-align:center">{{ciudadano.empresa}}</p>
                  </el-form-item>
                  <el-form-item label="" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px; text-align:center">{{ciudadano.correo}}</p>
                  </el-form-item>
                  <el-form-item label="" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px; text-align:center">{{ciudadano.telefono}}</p>
                  </el-form-item>
                  <el-form-item label="" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px; text-align:center">{{ciudadano.direccionNo}}</p>
                  </el-form-item>
                </el-form>
              </div>

              <div class="box-center d-none d-lg-block">
                <el-form :model="ciudadano" ref="ciudadano" label-position="right" label-width="200px" size="mini">
                  <el-form-item label="Ruc:" style="font-weight: bold;">
                    <p style="font-weight: normal;">{{ciudadano.ruc}}</p>
                  </el-form-item>
                  <el-form-item label="Razón Social:" style="font-weight: bold;">
                    <p style="font-weight: normal;">{{ciudadano.empresa}}</p>
                  </el-form-item>
                  <el-form-item label="Correo de Notificación:" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px;">{{ciudadano.correo}}</p>
                  </el-form-item>
                  <el-form-item label="Teléfono de Notificación:" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px;">{{ciudadano.telefono}}</p>
                  </el-form-item>
                  <el-form-item label="Dirección de Noificación:" style="font-weight: bold;">
                    <p style="font-weight: normal; margin-bottom: 0px;">{{ciudadano.direccionNo}}</p>
                  </el-form-item>
                </el-form>
              </div>
            </el-card>
            <el-card class="box-card" shadow="never" v-if="empresa==1">
            <div slot="header" class="clearfix">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="20" :md="16" :lg="14" :xl="1" style="width:100%">
                <span style="font-size:medium; min-with:340px">Datos Representante Legal</span>
              </el-col>
            </el-row>
            </div>

            <div class="row d-none d-block d-lg-none">
              <el-form :model="ciudadano" ref="ciudadano" label-position="top" label-width="100px" size="mini">

                <el-form-item label="" style="font-weight: bold;">
                  <p style="font-weight: normal; margin-bottom: 0px; text-align:center">{{ciudadano.docIdentidad}}</p>
                </el-form-item>
                <el-form-item label="" style="font-weight: bold;">
                  <p style="font-weight: normal; margin-bottom: 0px; text-align:center">{{ciudadano.ciudadano}}</p>
                </el-form-item>
              </el-form>
            </div>


              <div class="box-center d-none d-lg-block">
              <el-form :model="ciudadano" ref="ciudadano" label-position="right" label-width="200px" size="mini">

                <el-form-item label="Documento de Identidad:" style="font-weight: bold;">
                  <el-col :xs="24">
                  <p style="font-weight: normal; margin-bottom: 0px; whidth:100%">{{ciudadano.docIdentidad}}</p>
                  </el-col>
                </el-form-item>

              <el-form-item label="Nombres y Apellidos:" style="font-weight: bold;">
                <p style="font-weight: normal; margin-bottom: 0px;">{{ciudadano.ciudadano}}</p>
              </el-form-item>
            </el-form>
            </div>
          </el-card>
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span style="font-size:medium">Datos del Expediente</span>
              </div>
                <div class="row d-none d-block d-lg-none">
                  <el-form :model="ciudadano" ref="ciudadano" label-position="right" label-width="150px" size="mini" :inline="true">
                    <el-form-item label="Expediente:" style="font-weight: bold;">
                      <p style="font-weight: normal;">{{ciudadano.codigoExp}}</p>
                    </el-form-item>
                    <el-form-item label="Estado:" style="font-weight: bold;">
                      <p style="font-weight: normal;">{{ciudadano.estado}}</p>
                    </el-form-item>
                  </el-form>
                </div>

                <div class="box-center d-none d-lg-block">
                  <el-form :model="ciudadano" ref="ciudadano" label-position="right" label-width="150px" size="mini" :inline="true">
                    <el-form-item label="Código. Expediente:" style="font-weight: bold;">
                      <p style="font-weight: normal;">{{ciudadano.codigoExp}}</p>
                    </el-form-item>
                    <el-form-item label="Estado:" style="font-weight: bold;">
                      <p style="font-weight: normal;">{{ciudadano.estado}}</p>
                    </el-form-item>
                    <!-- <el-form-item label="Procedimiento:" style="font-weight: bold; word-break: break-word;">
                      <p style="font-weight: normal;">{{ciudadano.procedimiento}}</p>
                    </el-form-item> -->
                  </el-form>
                </div>
                <!-- <el-row class="el-form-item el-form-item--mini">
                    <el-col class="el-form-item__label" style="vertical-align:top; text-align: right; word-break: break-word; margin-bottom: 0.5rem; width: 150px; height: 28px;" :span="6">
                        <strong style="vertical-align:top;"> Generado: </strong>
                    </el-col>
                    <el-col class="el-form-item__content" :span="18">
                        {{ ciudadano.tipo === 'P' ? 'MODALIDAD PRESENCIAL' : 'POR CASILLA ELECTRÓNICA' }}
                    </el-col>
                </el-row> -->
                <el-row class="el-form-item el-form-item--mini">
                    <el-col class="el-form-item__label" style="vertical-align:top; text-align: right; word-break: break-word; margin-bottom: 0.5rem; width: 150px; height: 28px;" :span="6">
                        <strong style="vertical-align:top;"> Procedimiento: </strong>
                    </el-col>
                    <el-col class="el-form-item__content" :span="18">
                        {{ ciudadano.procedimiento }}
                    </el-col>
                </el-row>
                <el-row class="el-form-item el-form-item--mini">
                    <el-col class="el-form-item__label" style="vertical-align:top; text-align: right; word-break: break-word; margin-bottom: 0.5rem; width: 150px; height: 28px;">
                        <strong style="vertical-align:top;"> Asunto: </strong>
                    </el-col>
                    <el-col class="el-form-item__content" :span="18">
                        {{ ciudadano.asunto }}
                    </el-col>
                </el-row>
                <!-- <el-form :model="ciudadano" ref="ciudadano" label-position="top" label-width="150px" size="mini">
                    <el-row>
                        <el-col class="el-form-item__label" style="text-align: justify; word-break: break-word;"><strong> Procedimiento: </strong> {{ ciudadano.procedimiento }} </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="el-form-item__label" style="word-break: break-word; text-align: justify; width: 150px; padding: 0 12px 0 0; margin-right: 10px;"><strong> Asunto: </strong>{{ ciudadano.asunto }}</el-col>
                    </el-row>
                </el-form> -->
            </el-card>
            <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
              <span style="font-size:medium">Documentos que adjunta</span>
            </div>
                <el-table
                    :data="archivos"
                    border
                    size="mini"
                    style="width: 100%">
                    <el-table-column label="Item" width="60%" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.index }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Descripcion" align="rigth" min-width="100">
                        <template slot-scope="scope">
                            <span>{{ scope.row.descripcion }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Accion" width="100" align="center" v-on:click="false">
                      <template slot-scope="scope">
                          <el-button type="warning" size="mini" @click="descargararchivo(scope.row.id)">
                            Descargar
                          </el-button>
                      </template>
                    </el-table-column>
                </el-table>
            </el-card>
          </div>
      </el-dialog>
      </div>
      <template v-if="opcionModal == 1">
        <accion-component
            :expediente-id="expediente_id"
            :tramite="tram_nombre"
            :codigo="codigo"
            :key="key_accion"
        ></accion-component>
      </template>
      <!-- <template v-if="opcionModal == 1">
        <observacion></observacion>
      </template> -->
      <!-- <template v-if="opcionModal == 2">
        <requisitos :expediente_id="expediente_id"></requisitos>
      </template>
      <template v-if="opcionModal == 3">
        <confirmar-atencion-component :expediente_id="expediente_id"></confirmar-atencion-component>
      </template>
      <template v-if="opcionModal == 4">
        <apelar-resultado-component :expediente_id="expediente_id"></apelar-resultado-component>
      </template> -->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import SolicitudResource from "@/api/solicitud";
import ArchivoResource from "@/api/archivo"
import AccionComponent from "@/components/AccionComponent.vue";
import Observacion from "@/components/LevantarObservacionComponent";
import ConfirmarAtencionComponent from  '@/components/ConfirmarAtencionComponent'
import ApelarResultadoComponent from  '@/components/ApelarResultadoComponent'


const solicitudResource = new SolicitudResource();
const archivoResource = new ArchivoResource();

export default {
    name:"MiCasilla",
    components: { Pagination, Observacion, ConfirmarAtencionComponent, ApelarResultadoComponent, AccionComponent },

    data(){
      return {

        loading:false,
        loadmov:false,
        loaddet:false,
            list:[],
            movimientos:[],

        query: {
                page: 1,
                limit: 5,
                buscar:"",
                },
        query2: {
                page: 1,
                limit: 5,
                buscar:"",
                },

        qmov: {
                page: 1,
                limit: 5,
                id:"",
                },

        total: 0,
        total2: 0,
        tomov: 0,

        form:
            {
              tramite:''
            },

        //para dialog
        FormVisible:false,
        empresa:0,
        observado:0,
        archivos:[],
        ciudadano:
        {
          docIdentidad:'',
          ciudadano:'',
          telefono:'',
          direccionNo:'',
          correo:'',
          procedimiento:'',
          nroExp:'',
          estado:'',
          asunto:'',
          tipo: ''
        },

        opcionModal:0,
        expediente_id:'',
        tram_nombre:'',
        codigo:'',
        obs_descripcion:'',
        key_accion: 0
      }
    },

    created() {
      this.getTramites();
      this.$parent.boton=false;
      },

    methods:
    {

      async getTramites() {
        this.movimientos=null;
        this.form.tramite="";
        this.total2=0;
        const { limit, page } = this.query;
        this.query.buscar='';
        this.loading = true;
        const { data, meta } = await solicitudResource.list(this.query);
        this.list = data;
        this.list.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1; // para item nro de filas
          if(element['empresa']!=null)
          {
            element['solicitante']=element['empresa'];
          }
          else
          {
            element['solicitante']=element['persona'];
          }
          if(element['estado']=='OBSERVADO')
          {
            element['observacion']=element['descripcion'];
            //this.obs_descripcion=element['descripcion'];
          }
          let temp='';
          if(element['denominacion']=='[TRAMITES OTROS]')
          {
            temp='TRAMITES OTROS';
            element['denominacion']=temp+': '+element['asunto'];
          }
        });
        this.total = meta.total;
        this.loading=false;
        this.click=0;

      },
      async buscarTramites() {
        if(this.form.tramite!=''){
        this.movimientos=null;
        const { limit, page } = this.query2; // para paginacion
        this.query2.buscar=this.form.tramite;
        this.loading = true;
        const { data, meta } = await solicitudResource.list(this.query2);
        this.list = data;
        this.list.forEach((element, index) => {
          element['index'] = (page - 1) * limit + index + 1; // para item nro de filas
          if(element['empresa']!=null)
          {
            element['solicitante']=element['empresa'];
          }
          else
          {
            element['solicitante']=element['persona'];
          }
          let temp='';
          if(element['denominacion']=='[TRAMITES OTROS]')
          {
            temp=element['denominacion'];
            element['denominacion']=temp+': '+element['asunto'];
          }
        });
        this.total=0;
        this.total2 = meta.total;
        this.loading=false;
       }
       else
       {
         this.$message({
          message: "Ingrese el número de expediente que desea buscar",
          type: "info",
          duration: 1 * 3000
        });
       }

      },

      async getmovimientos(value, column, row){

        //console.log(column['label']);

        if(column['label']!='Detalles' && column['label']!='Acciones')
        {
          //this.currentRow = value;
          const { limit, page } = this.qmov;
          this.qmov.id=value['id'];
          this.loadmov = true;
          const { data, meta } = await solicitudResource.getmovimientos(this.qmov);
          this.movimientos = data;
          this.movimientos.forEach((element, index) => {
            element['index'] = (page - 1) * limit + index + 1; // para item nro de filas
          });
          //this.total = meta.total;
          this.loadmov=false;
        }
        else
        {
          this.$refs.expediente.setCurrentRow(row);
          this.movimientos=null;
        }
      },

      getDetallepdf(id)
      {
        window.open('detalleSolicitudpdf/'+id,'_blank');
      },

      getDetalles(id)
      {
        this.loading=true;
        solicitudResource.getdetalles(id).then(response=>{
          this.ciudadano=response;
          if(this.ciudadano.empresa!=null){
            this.empresa=1;
          }
          else{
            this.empresa=0;
          }
          this.archivos=response.archivos;
          this.archivos.forEach((element, index) => {
          element['index'] = index + 1; // para item nro de filas
        });
        this.FormVisible=true;
        this.loading=false;
        }).catch(error => {
						console.log(error);
						me.$message({
							message: "Ocurrió un error inesperado",
							type: 'error',
							duration: 5 * 1000
            });
            this.FormVisible=false;
            this.loading=false;
          });
          //console.log(this.archivos);
        //console.log(this.ciudadano);
      },

      descargararchivo(id)
      {
        this.loaddet = true
        archivoResource.descargarArchivo(id).then(response => {
          const url = window.URL.createObjectURL(
            new Blob([response], { type: 'application/pdf' })
          )
          window.open(url)
        }).catch((error) => {
          this.$message({
            showClose: true,
            message: 'No hemos podido encontrar el archivo',
            type: 'error'
          })
          console.log(error)
        }).finally(() => {
          this.loaddet = false
        })
      },
      abrirModal(expediente_id, opcion,codigo,tramite,descripcion)
      {
        this.tram_nombre= tramite;
        this.codigo = codigo;
        this.expediente_id = expediente_id;
        if(opcion === 1) this.key_accion++;
        this.opcionModal = opcion;
        this.obs_descripcion = descripcion;
      }
    }
}
</script>

<style>
  .el-table .cell{
    word-break: break-word;
    text-align: initial;
    font-size: 10px;
  }

  .el-dialog{
    width: 90%;
    max-width: 700px;
  }

  #modalDetalle .el-loading-mask {
    position: fixed;
  }

</style>
