<template>
<div class="app-container bg-white">
      <div class="app-container bg-white">
          <br>
          <el-row :gutter="10">
            <div class="box-center d-none d-lg-block">
              <el-col :xs="24" :sm="20" :md="16" :lg="14" :xl="1" style="width:100%">
                <h5 style="float:left; padding-left: 15px;">Seguimiento de Trámites</h5>
              </el-col>
            </div>
            <div class="row d-none d-block d-lg-none">
              <el-col :xs="24" :sm="20" :md="16" :lg="14" :xl="1" style="width:100%">
                <h5 style=" float:left; padding-left: 15px;">Seguimiento de Trámites</h5>
              </el-col>
            </div>
          </el-row>
          <el-divider></el-divider>
              <div>
                <el-form :model="form" ref="form" onsubmit="return false;" label-width="120px" style="margin-bottom: 20px;" size="mini">
                <el-row style="margin-top: 20px;">
                  <el-col :xs="24" :span="12">
                    <el-form-item label="Buscar:">
                      <el-input type="text" v-model="form.tramite" placeholder="Nro. EXPE./ DNI/ RUC/ SOLICITANTE/ EMPRESA"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :span="12" style="margin-bottom: 10px;">
                    <el-form-item>
                      <el-button size="mini" type="primary" icon="el-icon-search" @click="buscarTramites()">Buscar</el-button>
                      <el-button size="mini" type="primary" icon="el-icon-refresh" @click="getTramites()">Todos</el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
                </el-form>
              </div>
          <el-card style="padding-bottom: 20px;">
            <div class="col-12">
            <el-col :xs="24" :sm="22" :md="24" :lg="24" :xl="24">
              <el-table
                  ref="expediente"
                  :data="list"
                  v-loading="loading"
                  border
                  style="width: 100%"
                  max-height="360"
                  size="mini"
                  highlight-current-row
                  @cell-click="getmovimientos"
                  >
                  <el-table-column label="Item" width="40" align="center" prop="item">
                      <template slot-scope="scope">
                          <span>{{ scope.row.index }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Descripción" align="rigth" min-width="250">
                      <template slot-scope="scope">
                          <span>{{ scope.row.denominacion }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Código Expediente" width="100" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.codigoExpediente }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Asunto" width="250" align="rigth">
                      <template slot-scope="scope">
                          <span>{{ scope.row.asunto }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Fecha Registro" width="100" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.fecha }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Solicitante" width="180" align="rigth">
                      <template slot-scope="scope">
                          <i v-if="scope.row.empresa==null" class="el-icon-s-custom"></i>
                          <i v-if="scope.row.empresa!=null" class="el-icon-office-building"></i>
                          <span>{{ scope.row.solicitante }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Estado" width="100" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.estado }}</span>
                      </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </div>

          </el-card>
              <div class="box-card">
                    <pagination v-show="total>0"
                        :total="total"
                        :page.sync="query.page"
                        :limit.sync="query.limit"
                        @pagination="getTramites" />
              </div>
              <div class="box-card">
                    <pagination v-show="total2>0"
                        :total="total2"
                        :page.sync="query2.page"
                        :limit.sync="query2.limit"
                        @pagination="buscarTramites" />
              </div>
              <div class="box-card">
                    <pagination v-show="total3>0"
                        :total="total3"
                        :page.sync="query3.page"
                        :limit.sync="query3.limit"
                        @pagination="filtrarTramites" />
              </div>
        <div class="app-container bg-white">
          <br>
          <el-row>
            <el-col :span="24">
                <div class="grid-content bg-white">
                      <h5>Movimientos</h5>
                </div>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-card style="padding-bottom: 20px; margin-bottom: 30px;">
            <div class="col-12">
            <el-col :xs="24" :sm="22" :md="24" :lg="24" :xl="24">
              <el-table
                  :data="movimientos"
                  v-loading="loadmov"
                  border
                  size="small"
                  style="width: 100%">
                  <el-table-column label="Item" width="60%" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.index }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Acción" align="rigth" min-width="100">
                      <template slot-scope="scope">
                          <span>{{ scope.row.nombre }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Descripción" align="rigth" min-width="100">
                      <template slot-scope="scope">
                          <span>{{ scope.row.descripcion }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Fecha Movimiento" width="180" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.fecha }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Origen" width="200" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.origen }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Destino" width="200" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.destino }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="Folios" width="60%" align="center">
                      <template slot-scope="scope">
                          <span>{{ scope.row.folios }}</span>
                      </template>
                  </el-table-column>
                </el-table>
              </el-col>
              </div>
              </el-card>

      </div>

      </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
//import SolicitudResource from "@/api/solicitud";
import ExteriorResource from "@/api/exterior";
//import Recepcion from "@/components/RecepcionComponent";
//import Movimiento from "@/components/MovimientoComponent";
//import Revisar from "@/components/RevisarComponent";

//const solicitudResource = new SolicitudResource();
const exteriorResource= new ExteriorResource();

export default {

    components: { Pagination },
    data() {
        return {
            list:[],
            listproce:[],
            loading:false,
            loadmov:false,
            estados:[],
            proce:[],
            accion:[],
            accionSeleccionada: [],
            movimientos:[],

            query: {
                page: 1,
                limit: 10,
                buscar:"",
                testados:1,
                },
            //buscar
            query2: {
                page: 1,
                limit: 10,
                buscar:"",
                testados:1,
                },
            //filtrar
            query3: {
                page: 1,
                limit: 10,
                proce:"",
                estado:""
                },

            qmov: {
                page: 1,
                limit: 10,
                id:''
                },

            total: 0,
            total2: 0,
            total3:0,

            form:
            {
              tramite:'',
              tipobusqueda:"1",
              tipofiltro:"1",
              procedimiento:'',
              estado:'',
              acciones:'',
              type:[]

            },
            opcionModal: 0,
            expediente_id: 0,
            ventanilla: ''
        };
    },
    created() {

        this.getTramites();
        //this.getEstados();
        //this.getProcedimientos()

    },
    methods: {

        limpiaEntidad()
        {
          this.form.tramite = '';
          this.form.procedimiento='';
          this.form.estado='';
        },

      async getTramites()
        {
            this.form.tramite="";
            this.form.procedimiento="";
            this.form.estado="";
            this.total3=0;
            this.total2=0;
            const { limit, page } = this.query;
            this.query.buscar='';
            this.loading = true;
            const { data, meta } = await exteriorResource.list(this.query);
            this.list = data;
            this.list.forEach((element, index) => {
              element['index'] = (page - 1) * limit + index + 1; // para item nro de filas
              if(element['empresa']!=null)
              {
                element['solicitante']=element['empresa'];
              }
              else
              {
                element['solicitante']=element['persona'];
              }
              let temp='';
              if(element['denominacion']=='[TRAMITES OTROS]')
              {
                    temp='TRAMITES OTROS';
                    element['denominacion']=temp+': '+element['asunto'];
              }
            });
            this.total = meta.total;
            this.loading=false;
        },

        async buscarTramites(){

            if(this.form.tramite!=''){
              this.movimientos=null;
              this.total=0;
              this.total3=0;
              const { limit, page } = this.query2; // para paginacion
              this.query2.buscar=this.form.tramite;
              this.loading = true;
              const { data, meta } = await exteriorResource.list(this.query2);
              this.list = data;
              this.list.forEach((element, index) => {
                element['index'] = (page - 1) * limit + index + 1; // para item nro de filas
                if(element['empresa']!=null)
                {
                  element['solicitante']=element['empresa'];
                }
                else
                {
                  element['solicitante']=element['persona'];
                }
              });
              this.total2 = meta.total;
              this.loading=false;
            }
            else
            {
              this.$message({
                message: "Ingrese el número de expediente que desea buscar",
                type: "info",
                duration: 1 * 3000
              });
            }
        },

       getEstados()
        {
          solicitudResource.getEstados().then(response=>
          {
            this.estados=response.data;
          }).catch(error => {
						console.log(error);
						me.$message({
							message: "Ocurrió un error inesperado",
							type: 'error',
							duration: 5 * 1000
            });
          });

        },
        getProcedimientos()
        {
          solicitudResource.getProcedimientos().then(response=>
          {
            this.proce=response.data;
          }).catch(error => {
						console.log(error);
						me.$message({
							message: "Ocurrió un error inesperado",
							type: 'error',
							duration: 5 * 1000
            });
          });

        },

        async filtrarTramites()
        {
          if(this.permissions.find(element => element.name === 'smpv.expedientes.listar') != null)
          {
            this.form.tramite="";
            this.total=0;
            this.total2=0;
            this.movimientos=null;
            const { limit, page } = this.query3;
            this.query3.proce=this.form.procedimiento;
            this.query3.estado=this.form.estado;
            this.loading = true;
            const { data, meta } = await solicitudResource.filtrar(this.query3);
            this.list = data;
            this.list.forEach((element, index) => {
              element['index'] = (page - 1) * limit + index + 1; // para item nro de filas
            });
            this.total3 = meta.total;
            this.loading=false;
          }
        },

        async getmovimientos(value, column, row){

          if(column['label']!='Acciones')
          {
            const { limit, page } = this.qmov;
            this.qmov.id=value['id'];
            this.loadmov = true;
            const { data, meta } = await exteriorResource.getmovimientos(this.qmov);
            this.movimientos = data;
            this.movimientos.forEach((element, index) => {
              element['index'] = (page - 1) * limit + index + 1; // para item nro de filas
            });
            this.loadmov=false;
          }
          else
          {
            this.$refs.expediente.setCurrentRow(row);
            this.movimientos=null;
          }
      },

      abrirModal(expediente_id, opcion)
      {
        this.expediente_id = expediente_id;
        this.opcionModal = opcion;
      },

      cambiofiltro(tipo)
      {
        this.query.testados=tipo;
        this.query2.testados=tipo;
        this.getTramites();
      }
    },

};
</script>

<style>
  .el-table .cell{
    word-break: break-word;
    font-size: 9px;
  }

  .el-select-dropdown{
    max-width: 50% !important;
  }

</style>
