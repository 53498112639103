import request from "@/utils/request";
import Resource from "@/api/resource";

class AsistenciaResource extends Resource {
    constructor() {
        super("expediente");
    }

    selectFind(query){
        return request({
            url: '/' + this.uri + '/selectfind',
            method: "post",
            data: query,
        });
    }
    //   permissions(id) {
    //     return request({
    //       url: '/' + this.uri + '/' + id + '/permissions',
    //       method: 'get',
    //     });
    //   }

    //   updatePermission(id, permissions) {
    //     return request({
    //       url: '/' + this.uri + '/' + id + '/permissions',
    //       method: 'put',
    //       data: permissions,
    //     });
    //   }
}

export { AsistenciaResource as default };
