<template>
  <el-dialog
    title="Acciones por Movimientos"
    :visible.sync="visible"
    width='90%'
    @close="cerrarModal"
    :close-on-click-modal="false" :close-on-press-escape="false"
  >
    <div style="margin-top: 1%;">
        <div slot="header" class="clearfix" style="margin-top: 5px; margin-bottom: 3%;">
            <el-col>
                <span style="font-size:small"><strong>COD. EXPEDIENTE: </strong> {{ codigo }}</span>
            </el-col>
            <el-col>
                <span style="font-size:small"> <strong>PROCEDIMIENTO: </strong> {{ tramite }}</span>
            </el-col>
        </div>
        <el-table
            :data="movimientos"
            v-loading="load"
            border
            size="small"
            style="width: 100%;"
        >
            <el-table-column label="Item" width="40" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.item }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Acción | Motivo" align="rigth" min-width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.accion_motivo }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Estado" width="80" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.estado }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Opciones" width="180" align="center">
                <template slot-scope="scope">
                    <template v-if="scope.row.accion_id === 4">
                        <el-col>
                            <el-button
                            v-if="(scope.row.accion_id === 4 && scope.row.motivo_id === 10 && scope.row.estado_id === 2)"
                            size="mini"
                            type="danger"
                            @click="abrirModal(scope.row.id, 1)"
                            > Ver Notificación</el-button>
                        </el-col>
                        <el-col v-if="(scope.row.accion_id === 4 && scope.row.motivo_id === 12 && scope.row.estado_id === 2)">
                            <el-button
                            size="mini"
                            type="success"
                            @click="abrirModal(scope.row.id, 2)"
                            >Ver Notificación</el-button>

                        </el-col>
                        <el-col v-if="scope.row.accion_id === 4 && scope.row.motivo_id === 13 && scope.row.estado_id === 2">
                            <el-button
                            size="mini"
                            type="success"
                            @click="abrirModal(scope.row.id, 3)"
                            >Responder Notificación</el-button>
                        </el-col>
                    </template>
                    <template v-else-if="scope.row.accion_id === 3">
                        <el-col v-if="(scope.row.accion_id === 3 && scope.row.motivo_id === 9 && scope.row.estado_id === 6) && scope.row.motivo_parent === 10">
                            <el-button
                            size="mini"
                            type="danger"
                            @click="abrirModal(scope.row.id, 1)"
                            > Atender Notificación</el-button>
                        </el-col>
                        <el-col v-else-if="(scope.row.accion_id === 3 && scope.row.motivo_id === 9 && scope.row.estado_id === 6) && scope.row.motivo_parent === 12">
                            <el-button
                            size="mini"
                            type="success"
                            @click="abrirModal(scope.row.id, 2)"
                            >Atender Notificación</el-button>
                        </el-col>
                        <el-col v-else>
                            <span>No hay opciones para este movimiento</span>
                        </el-col>
                    </template>
                    <template v-else>
                        <el-col v-if="scope.row.accion_id === 5 && scope.row.motivo_id === 17 && scope.row.estado_id === 8">
                            <el-button
                            size="mini"
                            type="success"
                            @click="abrirModal(scope.row.id, 4)"
                            >Apelar Resultado</el-button>
                        </el-col>
                        <el-col v-else-if="(scope.row.accion_id === 5 && scope.row.motivo_id === 89 && scope.row.estado_id === 6) && scope.row.motivo_parent === 10">
                            <el-button
                            size="mini"
                            type="danger"
                            @click="abrirModal(scope.row.id, 1)"
                            > Atender Notificación</el-button>
                        </el-col>
                        <span v-else>No hay opciones para este movimiento</span>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <template v-if="opcionM == 1">
        <observacion
            :expediente-id="expedienteId"
            :movimiento-id="movimiento_id"
            :codigo="codigo"
            :tramite="tramite"
            :presentacion="presentacion"
            @actualiza="getMovimientosActivos"
        ></observacion>
        </template>
        <template v-if="opcionM == 2">
        <requisitos
            :expediente-id="expedienteId"
            :movimiento-id="movimiento_id"
            :codigo="codigo"
            :tramite="tramite"
            :presentacion="presentacion"
            @actualiza="getMovimientosActivos"
        ></requisitos>
        </template>
        <template v-if="opcionM == 3">
        <confirmar-atencion-component
            :expediente_id="expedienteId"
            :movimiento-id="movimiento_id"
            :presentacion="presentacion"
            @actualiza="getMovimientosActivos"
        ></confirmar-atencion-component>
        </template>
        <template v-if="opcionM == 4">
        <apelar-resultado-component
            :expediente_id="expedienteId"
            :movimiento-id="movimiento_id"
            :presentacion="presentacion"
            @actualiza="getMovimientosActivos"
        ></apelar-resultado-component>
        </template>

    </div>

  </el-dialog>
</template>

<script>
import SolicitudResource from "@/api/solicitud";
const solicitudResource = new SolicitudResource();

export default {
    props: {
        expedienteId: 0,
        tramite: '',
        codigo: ''
    },
    data() {
        return {
            movimientos: [],
            presentacion: '',
            load: false,
            visible: false,
            opcionM: 0,
            keyObservacion: 0,
            movimiento_id: 0
        }
    },
    created() {  },
    mounted() {
        this.getMovimientosActivos()
    },
    methods: {
        getMovimientosActivos() {
            this.$parent.loading = true
            this.load = true
            solicitudResource.getMovimientoActivo(this.expedienteId).then(response => {
                console.log(response.data)
                this.movimientos = response.data
                this.presentacion = response.presentacion
                this.movimientos.forEach((element, index) => {
                    element['item'] = index + 1;
                });
                this.visible = true
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.$parent.loading = false
                this.load = false
                this.opcionM = 0
            })
        },
        abrirModal(movimiento_id, opcion,tramite,descripcion)
        {
            // this.tram_nombre= tramite;
            // this.expediente_id = expediente_id;
            // if(opcion === 1) this.key_accion++;
            this.movimiento_id = movimiento_id;
            this.opcionM = opcion;
            // this.obs_descripcion = descripcion;
        },

        cerrarModal() {
            this.$parent.getTramites()
            this.$parent.opcionModal = 0
            this.$parent.tram_nombre = ''
            this.$parent.codigo = ''
            this.visible = false
        }
    }

}
</script>

<style>

</style>
