import request from "@/utils/request";
import Resource from "@/api/resource";

class ProcedimientosResource extends Resource {
  constructor() {
    super('procedimientos');
  }

 /* getRequisitos(id) {
    return request({
      url: '/' + 'requisitos' + '/' + id,
      method: 'get',
    });
  }*/

  getRequisitos(query) {
    return request({
      url: 'requisitos',
      method: 'get',
      params: query,
    });
  }

  getNombre(query)
  {
    return request({
      url: 'getNombreProcedimiento',
      method: 'get',
      params: query
    });
  }

  getformatos(id) {
    return request({
      url: `getFormatos/${id}`,
      method: 'get',
    });
  }

  getTramiteOtros() {
    return request({
      url: 'getTramiteOtros',
      method: 'get',
    });
  }

  getConceptos(id) {
    return request({
      url: 'getConceptos/' + id,
      method: 'get'
    })
  }

}

export { ProcedimientosResource as default };
