import request from "@/utils/request";
import Resource from "@/api/resource";

class SexoResource extends Resource {
  constructor() {

    super('sexo');
  }
  

}

export { SexoResource as default };