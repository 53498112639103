// Reporte Asistencia Control de Personal
<template>
  <!-- <div class="container"> -->
  <div style="width:auto; padding: 2px 0;">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="card-title">Búsqueda de Asistencia mensual por Área</span>
      </div>
      <el-form label-position="left" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Área">
              <el-select
                ref="nomArea"
                v-model="idXAreas"
                filterable
                remote
                reserve-keyword
                placeholder="Ingrese un área..."
                :remote-method="remoteMethod"
                :loading="loadingSelectArea"
                style="width:100%"
              >
                <el-option
                  v-for="item in optionsXAreas"
                  :key="item.id"
                  :label="item.nombre"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-form-item label="Fecha">
              <el-date-picker
                v-model="fecha"
                type="month"
                align="center"
                placeholder="Seleccione mes"
                style="width:100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!-- <el-form-item> -->
            <el-button :loading="listLoading" type="primary" @click="getListAsistencia">Buscar</el-button>
            <!-- </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="card-title">Reporte de asistencia mensual</span>
        <el-button
          v-if="this.asistenciaDeHoy"
          :loading="pdfLoading"
          size="mini"
          class="filter-item"
          style="float: right; margin-left: 10px;"
          type="primary"
          icon="icon-printer"
          @click="pdf"
        >&nbsp;PDF</el-button>
        <!-- <el-button
          size="mini"
          class="filter-item"
          style="float: right; margin-left: 10px;"
          type="primary"
          icon="icon-refresh"
          @click="handleRefresh"
        >Exportar</el-button>-->
      </div>
      <el-row :gutter="20">
        <el-col :span="24">
          <template>
            <el-table
              v-if="this.total > 0"
              ref="TablaAsistenciaTrabajadores"
              :data="listAsistenciaDelDia"
              row-key="id"
              v-loading="listLoading"
              height="450px"
              size="mini"
              highlight-current-row
              style="width:100%"
              :cell-style="{ heigth: '21px' }"
            >
              <el-table-column width="50" fixed>
                <template slot-scope="scope">
                  <span>{{ scope.row.index }}</span>
                </template>
              </el-table-column>
              <el-table-column label="ID" width="60" fixed>
                <template slot-scope="scope">
                  <span>{{ scope.row.cod_tobrero }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Apellidos y nombres" min-width="300" fixed>
                <template slot-scope="scope">
                  <span>{{ scope.row.apenom }}</span>
                </template>
              </el-table-column>
              <el-table-column label="DNI" width="80" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.dni }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Mod" width="45" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.trabajoxx | statusPRM">{{ row.trabajoxx }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="1" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d1 | statusMarca">{{ row.d1 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="2" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d2 | statusMarca">{{ row.d2 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="3" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d3 | statusMarca">{{ row.d3 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="4" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d4 | statusMarca">{{ row.d4 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="5" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d5 | statusMarca">{{ row.d5 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="6" width="30" align="center" style="margin:0">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d6 | statusMarca">{{ row.d6 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="7" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d7 | statusMarca">{{ row.d7 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="8" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d8 | statusMarca">{{ row.d8 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="9" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d9 | statusMarca">{{ row.d9 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="10" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d10 | statusMarca">{{ row.d10 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="11" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d11 | statusMarca">{{ row.d11 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="12" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d12 | statusMarca">{{ row.d12 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="13" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d13 | statusMarca">{{ row.d13 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="14" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d14 | statusMarca">{{ row.d14 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="15" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d15 | statusMarca">{{ row.d15 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="16" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d16 | statusMarca">{{ row.d16 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="17" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d17 | statusMarca">{{ row.d17 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="18" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d18 | statusMarca">{{ row.d18 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="19" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d19 | statusMarca">{{ row.d19 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="20" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d20 | statusMarca">{{ row.d20 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="21" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d21 | statusMarca">{{ row.d21 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="22" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d22 | statusMarca">{{ row.d22 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="23" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d23 | statusMarca">{{ row.d23 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="24" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d24 | statusMarca">{{ row.d24 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="25" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d25 | statusMarca">{{ row.d25 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="26" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d26 | statusMarca">{{ row.d26 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="27" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d27 | statusMarca">{{ row.d27 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="28" width="30" align="center">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d28 | statusMarca">{{ row.d28 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="29" width="30" align="center" v-if="this.colnumdias>=29">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d29 | statusMarca">{{ row.d29 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="30" width="30" align="center" v-if="this.colnumdias>=30">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d30 | statusMarca">{{ row.d30 }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="31" width="30" align="center" v-if="this.colnumdias>=31">
                <template slot-scope="{ row }">
                  <el-tag size="mini" :type="row.d31 | statusMarca">{{ row.d31 }}</el-tag>
                </template>
              </el-table-column>

              <!-- <template v-if="this.colnumdias>=29">
                <el-table-column label="29" width="30" align="center">
                  <template slot-scope="{ row }">
                    <el-tag size="mini" :type="row.d29 | statusMarca">{{ row.d29 }}</el-tag>
                  </template>
                </el-table-column>
              </template>-->

              <el-table-column label="TA" width="30" align="center" fixed="right">
                <template slot-scope="{ row }">
                  <span>{{ row.ta }}</span>
                </template>
              </el-table-column>
              <el-table-column label="TF" width="30" align="center" fixed="right">
                <template slot-scope="{ row }">
                  <span>{{ row.tf }}</span>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>


<script>
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
import AsistenciaResource from "@/api/asistencia";
import TobreroResource from "@/api/tobrero";
import XAreaResource from "@/api/xarea";

const asistenciaResource = new AsistenciaResource();
const tobreroResource = new TobreroResource();
const xareaResource = new XAreaResource();

export default {
  name: "rptAsistenciaMesCP",
  components: { Pagination },
  props: { auth: Object },
  directives: {
    focus: {
      inserted: function(el) {
        el.focus();
      }
    }
  },
  filters: {
    statusPRM(status) {
      const statusMap = {
        P: "success",
        R: "warning",
        M: "info"
      };
      return statusMap[status];
    },
    statusMarca(status) {
      const statusMap = {
        A: "success",
        F: "danger"
      };
      return statusMap[status];
    },
    statusCerrado(status) {
      const statusMap = {
        A: "success",
        C: "danger"
      };
      return statusMap[status];
    },
    formatdate(fecha) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    }
  },
  data() {
    return {
      listLoading: false,
      asistenciaDeHoy: false,
      listQuery: {
        page: 1,
        limit: 15,
        buscar: "",
        criterio: "",
        fecha: "", // fecha dd-MM-YYYY para indicar mes y año a reportar asistencia
        id_area: ""
      },
      nonAdminRoles: ["usuario"],
      currentUser_id: 0,
      currentAsistencia: {},
      colnumdias: 0, //num de columnas del reporte

      fecha: "",
      listAreas: null,
      idXAreas: "",
      optionsXAreas: [],
      loadingSelectArea: false,

      pdfLoading: false,
      datosJefe: null,
      listAsistenciaDelDia: [], // almacena la lista de los ultimos movimientos del area origen
      asistenciaHoy: {},
      pagination: [],
      errorsForms: [],
      total: 0,
      vdata: {},
      vmeta: {}
    };
  },
  created() {
    //this.getListTrabajadoresArea();
    this.getListXAreas();
  },
  methods: {
    getListTrabajadoresArea() {
      this.asistenciaDeHoy = false;
      // obtener el jefe de area a reportar
      tobreroResource
        .getTobreroData(this.auth.cod_tobrero)
        .then(response => {
          this.datosJefe = response.data;
          //this.getList();
        })
        .catch(error => {
          console.log(error);
        });
    },

    /* accede remotamente a listado de areas para select */
    remoteMethod(query) {
      if (query !== "") {
        this.loadingSelectArea = true;
        setTimeout(() => {
          this.loadingSelectArea = false;
          this.optionsXAreas = this.listAreas.filter(item => {
            return item.nombre.toUpperCase().indexOf(query.toUpperCase()) > -1;
          });
        }, 200);
      } else {
        this.optionsXAreas = [];
      }
    },

    async getListAsistencia() {
      if (this.fecha === "" || this.fecha === null) {
        this.$message({
          message: "Debe seleccionar un mes a listar",
          type: "warning",
          duration: 1 * 1000
        });
        return false;
      }
      this.listQuery.page = 1;
      this.total = 0;
      this.colnumdias = 0;
      this.listAsistenciaDelDia = [];

      const { limit, page } = this.listQuery;
      this.listLoading = true;
      this.listQuery.id_area = this.idXAreas;
      this.listQuery.fecha = this.fecha;
      const { data, meta } = await asistenciaResource.getListMonth(
        this.listQuery
      );
      this.listAsistenciaDelDia = data;
      //console.log(meta.total);
      if (meta.total > 0) {
        this.listAsistenciaDelDia.forEach((element, index) => {
          element["index"] = (page - 1) * limit + index + 1;
        });
        this.total = meta.total;
        this.colnumdias = this.listAsistenciaDelDia[0].numdias;
        this.asistenciaDeHoy = true;
      } else {
        this.asistenciaDeHoy = false;
      }
      this.listLoading = false;
      //this.asistenciaDeHoy = true;
    },

    getListXAreas() {
      xareaResource
        .list({})
        .then(response => {
          this.listAreas = response.data;
          this.$nextTick(() => {
            this.$refs.nomArea.focus();
          });
        })
        .catch(error => {
          console.log(error);
        });
    },

    handleBuscar() {
      this.listQuery.page = 1;
      this.total = 0;
      this.colnumdias = 0;
      this.listAsistenciaDelDia = [];
      this.getList();
    },

    pdf() {
      this.pdfLoading = true;
      this.listQuery.fecha = this.fecha;
      this.listQuery.id_area = this.idXAreas;
      //this.listQuery.id_jefearea = this.datosJefe.cod_tobrero;
      asistenciaResource
        .pdfListMonth(this.listQuery)
        .then(response => {
          // var byteCharacters = atob(response.pdf);
          // var byteNumbers = new Array(byteCharacters.length);
          // for (var i = 0; i < byteCharacters.length; i++) {
          //   byteNumbers[i] = byteCharacters.charCodeAt(i);
          // }
          // var byteArray = new Uint8Array(byteNumbers);
          // var file = new Blob([byteArray], { type: "application/pdf;base64" });
          // var fileURL = URL.createObjectURL(file);
          // window.open(fileURL);
          const url = window.URL.createObjectURL(
            new Blob([response], { type: "application/pdf" })
          );
          window.open(url);

          this.pdfLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.pdfLoading = false;
        });
    }
  },

  mounted() {
    //this.listarUsuarios(this.buscar, this.criterio, (this.tipoAccion = 0));
  }
};
</script>
<style scoped type="text/css">
.swal2-container {
  z-index: 10000;
}

.el-form-item {
  margin-bottom: 5px !important;
}

/* .el-row {
  margin-bottom: 20px;
} */
/* .el-select .el-input.is-focus  */
.el-select .el-input__inner {
  /* border-color: #409EFF; */
  text-transform: uppercase;
}
.el-row:last-child {
  margin-bottom: 0;
}
</style>
