<template>
  <el-row :gutter="12" justify="center">
    <el-col :xs="24" :sm="20" :md="16" :lg="8" :xl="4" :span="8">
        <el-card shadow="always" v-if="auth.docIdentidad!=null">

            <div slot="header" class="clearfix">
              <h5>Datos de Ciudadano</h5>
            </div>
            <el-row justify="center">
              <div class="box-center bg-center" style="text-align: center;">
                <el-avatar justify="center" shape="square" :size="100" :src="squareUrl">
                </el-avatar>
              </div>
            </el-row>
              <br>
            <div class="box-center">
              <div class="user-name text-center">
                {{ auth.docIdentidad }}
              </div>
              <div class="user-name text-center">
                {{ auth.nombre +' '+ auth.paterno +' '+ auth.materno }}
              </div>
              <div class="user-name text-center">
                {{ auth.direccionReniec }}
              </div>
              <div class="user-name text-center">
                {{ auth.ubigeoReniec }}
              </div>
              <div class="user-name text-center">
                {{ auth.correo}}
              </div>
            </div>
        </el-card>
        <el-card shadow="always" v-if="auth.docIdentidad==null">

            <div slot="header" class="clearfix">
              <h5>Datos de Empresa</h5>
            </div>
            <el-row justify="center">
              <div class="box-center bg-center" style="text-align: center;">
                <el-avatar justify="center" shape="square" :size="100" :src="squareUrl">
                </el-avatar>
              </div>
            </el-row>
              <br>
            <div class="box-center">
              <div class="user-name text-center">
                {{ auth.ruc }}
              </div>
              <div class="user-name text-center">
                {{ auth.razonSocial }}
              </div>
              <div class="user-name text-center">
                {{ auth.direccionSunat }}
              </div>
              <div class="user-name text-center">
                {{ auth.ubigeoSunat }}
              </div>
            </div>
        </el-card>
    </el-col>
    <el-col :span="3"><div class="grid-content bg-white"></div></el-col>
    <el-col :xs="24" :sm="20" :md="16" :lg="10" :xl="4" :span="8">
            <div class="box-center d-none d-xl-block">
                <img src="img/logo-mpc-2023-02.png" alt="" style="max-height: 226px; margin-top: 5%;">
            </div>
    </el-col>
    <el-col :span="5"><div class="grid-content bg-white"></div></el-col>
  </el-row>
</template>
<script>
export default {
  props: { auth: Object },
  data() {
    return {
      squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
    };
  }
}
</script>
<style>

</style>
