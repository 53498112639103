import request from '@/utils/request';

class EntidadResource
{
    validar(resource)
    {
        return request({
            url: 'entidad',
            method: 'post',
            data: resource
        });
    }

    store(resource) {
    return request({
        url: 'entidad',
        method: 'post',
        data: resource,
    });
    }
}

export { EntidadResource as default }