import request from "@/utils/request";
import Resource from "@/api/resource";

class UserResource extends Resource {
    constructor() {
        super("usuarios");
    }

    updatepass(resource) {
        return request({
          url: this.uri,
          method: 'put',
          data: resource,
        });
      }
    

    //   permissions(id) {
    //     return request({
    //       url: '/' + this.uri + '/' + id + '/permissions',
    //       method: 'get',
    //     });
    //   }

    //   updatePermission(id, permissions) {
    //     return request({
    //       url: '/' + this.uri + '/' + id + '/permissions',
    //       method: 'put',
    //       data: permissions,
    //     });
    //   }
}

export { UserResource as default };
