import request from '@/utils/request'
import Resource from '@/api/resource'

class ArchivoResource extends Resource {
  constructor() {
    super('archivo')
  }

  descargarArchivo(id) {
    return request({
      url: 'downloadArchivo/' + id,
      method: 'get',
      responseType: 'arraybuffer'
    })
  }
}

export { ArchivoResource as default }
