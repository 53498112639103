import request from "@/utils/request";
import Resource from "@/api/resource";

class PersonaResource extends Resource {
  constructor() {
    super('persona');
  }

}

export { PersonaResource as default };