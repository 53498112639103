/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from "vue";
import Cookies from "js-cookie";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/es";

//require("./bootstrap");

window.Vue = require("vue");

//import 'element-ui/lib/theme-chalk/index.css';
import * as filters from "./filters"; // global filters

Vue.use(ElementUI, {
    locale,
    size: Cookies.get("size") || "medium" // set element-ui default size
});

// register global utility filters.
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key)))
Vue.component(
    "dashboard",
    require("./components/DashboardComponent.vue").default
);
Vue.component(
    "asistencia",
    require("./components/AsistenciaComponent.vue").default
);
Vue.component(
    "updasistencia",
    require("./components/UpdAsistenciaComponent.vue").default
);
Vue.component(
    "rptasistenciames",
    require("./components/RptAsistenciaMesComponent.vue").default
);
Vue.component(
    "rptasistenciamescp",
    require("./components/RptAsistenciaMesCPComponent.vue").default
);
Vue.component(
    "actividades",
    require("./components/ActividadesComponent.vue").default
);

// Para CAsilla Electrónica
Vue.component(
    "registro",
    require("./components/RegistroComponent.vue").default
);

Vue.component(
    "cambiopassword",
    require("./components/CambioPassword.vue").default
);

Vue.component(
    "principal",
    require("./components/PrincipalComponent.vue").default
);

Vue.component(
    "micasilla",
    require("./components/MiCasillaComponent.vue").default
);
Vue.component(
    "tramite",
    require("./components/TramitesTupaComponent.vue").default
);
Vue.component(
    "formulario-tramite",
    require("./components/FormularioTramiteComponent.vue").default
);
Vue.component(
    "tramite-externo",
    require("./components/TramiteExteriorComponent.vue").default
);
Vue.component(
    "tupa-externo",
    require("./components/TupaExteriorComponent.vue").default
);
Vue.component(
    "exterior",
    require("./components/ExteriorComponent.vue").default
);
Vue.component(
    "observacion",
    require("./components/LevantarObservacionComponent.vue").default
);
Vue.component(
    "requisitos",
    require("./components/RequisitosComponent.vue").default
);

Vue.component(
    "confirmar-atencion-component",
    require("./components/ConfirmarAtencionComponent.vue").default
);
Vue.component(
    "apelar-resultado-component",
    require("./components/ApelarResultadoComponent.vue").default
);
Vue.component(
    "accion-component",
    require("./components/AccionComponent.vue").default
);

//Vue.component("usuarios",require("./components/UsuariosComponent.vue").default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app",

    data: {
        menu: 0,
        registro: 0, //esto sirve para verificar que pantalla se muestra en la validación y registro de datos
    }
});
