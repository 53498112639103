<template>
	<div style="margin-top: 3%;" v-loading="loading">
		<h3>{{ nombreProcedimiento }}</h3>
		<el-form
			ref="solicitud"
			label-width="120px"
			:model="solicitud"
			:rules="rules"
			:inline-message="true">
			<el-form-item label="Asunto:" prop="asunto">
				<el-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
					<el-input
						type="textarea"
						:rows="3"
						v-model="solicitud.asunto"
						placeholder="Por Ejem: Solicita Licencia de Funcionamiento"
						resize="none">
					</el-input>
				</el-col>
			</el-form-item>
			<el-form-item label="Tipo Documento" prop="documentoTipo_id">
				<el-col :xs="24" :sm="16" :md="12" :lg="6" :xl="4">
					<el-select
						v-model="solicitud.documentoTipo_id"
						placeholder="Seleccione el tipo de documento"
						style="width: 100%">
						<el-option
							v-for="item in documentos"
							:key="item.id"
							:label="item.nombre"
							:value="item.id">
						</el-option>
					</el-select>
				</el-col>
			</el-form-item>
			<el-form-item label="Nro. Doc" prop="nroDocumento">
				<el-col :xs="24" :sm="16" :md="12" :lg="6" :xl="4">
					<el-input v-model="solicitud.nroDocumento" placeholder="Por ejemplo, Inf. Nro 001-2020/MPC"></el-input>
				</el-col>
			</el-form-item>
			<el-form-item label="Fecha Doc." prop="fechaDocumento">
				<el-col :xs="24" :sm="16" :md="8" :lg="5" :xl="2">
					<el-date-picker
						v-model="solicitud.fechaDocumento"
						type="date"
						placeholder="Elija la fecha del documento"
						style="width: 100%;"
						format="dd/MM/yyyy"
						value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-col>
			</el-form-item>
			<el-form-item label="Nro. Folios:" prop="folios">
				<el-col :xs="24" :sm="4" :lg="2">
					<el-input-number v-model="solicitud.folios" :min="1" controls-position="right" style="width: 100%"></el-input-number>
				</el-col>
			</el-form-item>
			<el-form-item v-if="requierePago && seleccionaTipoPago" label="Pago" prop="pago">
				<el-select v-model="solicitud.tipoPago" @change="reseteaPago">
					<el-option label="Gratuito" value="0" />
					<el-option label="No Gratuito" value="1" />
				</el-select>
				<div>* El concepto gratuito es {{ conceptos[0].denominacion }}</div>
			</el-form-item>
			<el-form-item v-if="requierePago" label="Nro. Recibo" prop="nroRecibo">
				<el-row>
					<el-col :md="5" id="recibo">
						<el-input v-model="solicitud.nroRecibo" :disabled="disabledTipoPago">
							<el-select slot="prepend" v-model="solicitud.anioRecibo" >
								<el-option
                                    v-for="anio in solicitud.anios"
                                    :key="anio.value"
                                    :label="anio.value"
                                    :value="anio.value"
                                />
							</el-select>
						</el-input>
					</el-col>
					<el-col :md="4" style="padding-left: 1%">
						<el-button style="width: 100%" type="success" :disabled="disabledTipoPago" @click="comprobarRecibo">Verificar Recibo</el-button>
					</el-col>
				</el-row>
			</el-form-item>
			<div v-loading="requisitosLoading" v-if="requisitos.length > 0">
				<hr>
				<h5>Requisitos</h5>
				<hr>
				<el-form-item>
					<el-row style="margin-left: -120px;">
						<div v-for="(requisito, index) in requisitos" :key="index" style="margin-bottom: 2%;">
							<p align="justify" style="margin-bottom: 1%; line-height: 1.5;">{{ (index + 1) + '. ' +  requisito.denominacion}}<span v-if="requisito.id == 3 && requisito.etapaPresenta_id == 2"> (Se le notificará el monto a pagar durante el proceso)</span></p>
						</div>
					</el-row>
				</el-form-item>
			</div>
			<hr>
            <el-form-item prop="archivo">
			    <input style="margin-left: -120px;" type="file" id="archivo" @change="seleccionarArchivo" accept=".pdf">
            </el-form-item>
			<hr>
			<el-form-item>
				<el-button @click="registrar" type="primary">Registrar</el-button>
				<el-button @click="cancelar">Cancelar</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import DocumentoResource from "@/api/documento";
import ProcedimientosResource from "@/api/procedimientos";
import SolicitudResource from "@/api/solicitud";
import EntidadResource from "@/api/entidad";
import ReciboResource from "@/api/recibo";

const documentoResource = new DocumentoResource();
const procedimientoResource = new ProcedimientosResource();
const solicitudResource = new SolicitudResource();
const entidadResource = new EntidadResource();
const reciboResource = new ReciboResource();
export default {
	props: { procedimiento_id: Number, catproced_id:Number },
	data()
	{
		// validaciones
		var validaFolios = (rule, value, callback) => {
			if(value == undefined) callback(new Error("Por favor, ingrese el número de folios"));
			else if(value <= 0) callback(new Error("Por favor, ingrese un número de folios válido"));
			else callback();
        };

        var checkarchivo = (rule, value, callback) => {
            if(this.fsize==1)
            return callback(new Error('El archivo excede al peso máximo de 25 MB, por favor seleccione otro archivo'));
            else callback();
        };
		return {
			solicitud: {
				'procedimiento_id': this.procedimiento_id,
				'tipoPersona': '',
				'ruc': '',
				'razonSocial': '',
				'personaJuridica_id': 0,
				'asunto': '',
				'documentoTipo_id': null,
				'nroDocumento': '',
				'fechaDocumento': '',
				'folios': 1,
                'archivo': '',
                'archivosize': 0,
				'cajaRecibo_id': 0,
				'nroRecibo': '',
				'tipoPago': '0',
				'anioRecibo': 0,
                'anios': []
			},
            nombreProcedimiento: '',
            fsize:0,
			loading: false,
			requisitosLoading: false,
			documentos: [],
			query: {
				'idprocedimiento': 0
			},
			deshabilitar: false,
			requisitos: [],
			conceptos: [],
			requierePago: false,
			seleccionaTipoPago: false,
			rules: {
				asunto: [{
					required: true,
					message: 'Por favor, ingrese un asunto para el trámite',
					trigger: 'none'
				}],
				folios: [{
					validator: validaFolios, trigger: 'none'
                }],
                archivo: [{
                    validator: checkarchivo,
                    trigger: "none"
                }
                ],
			}
		};
	},
	computed: {
		disabledTipoPago: function(){
			if(this.solicitud.tipoPago === '1' && this.solicitud.cajaRecibo_id === 0) return false;
			else return true;
		}
	},
	methods: {
		async getNombreProcedimiento()
		{
			if(this.catproced_id== 999) this.nombreProcedimiento = "Otros Trámites";
			else
			{
				this.loading = true;
				this.query.idprocedimiento = this.procedimiento_id;
				let response = await procedimientoResource.getNombre(this.query);
				this.nombreProcedimiento = response.denominacion;
				this.loading = false;
			}
		},
		seleccionarArchivo(e)
		{
			let files = e.target.files;
			if(!files.length) return;
			this.creaArchivo(files[0]);
		},
		creaArchivo(file)
		{
            this.fsize=0;
            this.solicitud.archivosize='';
			let reader = new FileReader();
			let me = this;
			reader.onload = (e) => {
				me.solicitud.archivo = e.target.result;
            };
            this.solicitud.archivosize=file.size;
            reader.readAsDataURL(file);
            if(file.size>25000000){this.fsize=1;}
		},
		cancelar()
		{
            this.$parent.tramite = 0;
            this.$parent.catproced=999;
			this.$parent.opcion = 0;
			this.$parent.boton=false;
		},
		//llenar el combo de documentos
		getDocumentos()
		{
			documentoResource.listar()
			.then(response => {
				this.documentos = response;
			});
		},
		async getRequisitos()
		{
			this.requisitosLoading = true;
			this.query.idprocedimiento = this.procedimiento_id;
			let response = await procedimientoResource.getRequisitos(this.query);
			this.requisitos = response.data;
			const indicePago = this.requisitos.findIndex(elemento => elemento.id === 3)
			if(indicePago >= 0 && this.requisitos[indicePago].etapaPresenta_id === 1){
				await this.getConceptos(this.requisitos[indicePago].requisitoProcedimiento_id);
				let conceptoGratis = 0;
				let conceptoNoGratis = 0;
				this.conceptos.forEach(element => {
					const valor = parseFloat(element.valor)
					if(valor > 0 ) conceptoNoGratis++
					else conceptoGratis ++
				})
				if(conceptoGratis > 0 && conceptoNoGratis > 0)
				{
					this.requierePago = true;
					this.seleccionaTipoPago = true
					this.solicitud.tipoPago = '0'
				}
				else if (conceptoGratis > 0)
				{
					this.requierePago = false
					this.solicitud.tipoPago = '0'
				}
				else
				{
					this.requierePago = true
					this.solicitud.tipoPago = '1'
				}
			}
			else{
				this.requierePago = false;
				this.seleccionaTipoPago = false;
				this.solicitud.tipoPago = '0';
			}
			this.requisitosLoading = false;
		},
		async getConceptos(requisito)
		{
			const response = await procedimientoResource.getConceptos(requisito);
			this.conceptos = response.conceptos
			this.solicitud.anioRecibo = response.anio
            this.solicitud.anios = response.anios
		},
		limpiaEntidad()
		{
			this.solicitud.ruc = '';
			this.solicitud.razonSocial = '';
			this.solicitud.personaJuridica_id = 0;
			this.deshabilitar = false;
		},
		reseteaPago(){
			this.solicitud.nroRecibo = '';
			this.solicitud.cajaRecibo_id = 0;
		},
		comprobarRecibo() {
			if(this.solicitud.nroRecibo === '') Swal.fire(
				'¡Atención!',
				'No ha ingresado el número de recibo',
				'warning'
			);
			else {
				const query = {
					'nroRecibo': this.solicitud.nroRecibo,
					'procedimiento_id': this.procedimiento_id,
					'anio': this.solicitud.anioRecibo
				};
				this.loading = true;
				reciboResource.comprobar(query).then(response => {
					this.solicitud.cajaRecibo_id = 0;
					if(response.respuesta == 1)
					{
						Swal.fire(
							'¡Éxito!',
							'Recibo validado correctamente',
							'success'
						);
						this.solicitud.nroRecibo = response.recibo.nrorecibo;
						this.solicitud.cajaRecibo_id = parseInt(response.recibo.idrecibo);
					}
					else if(response.respuesta == 2)
					{
						Swal.fire(
							'Atención',
							response.mensaje,
							'warning'
						);
					}
					else if(response.respuesta == 0)
					{
						this.$message({
							message: response.mensaje,
							type: 'error',
							duration: 5 * 1000
						});
					}
				}).catch(error => {
					this.solicitud.cajaRecibo_id = 0;
					console.log(error);
					this.$message({
						message: 'Ha ocurrido un error, por favor, vuelva a intentarlo más tarde',
						type: 'error',
						duration: 5 * 1000
					});
				}).finally(() => this.loading = false)
			}
		},
		registrar()
		{
			this.$refs['solicitud'].validate(valid => {
				if(valid)
				{
					if(this.solicitud.tipoPago === "1" && this.solicitud.cajaRecibo_id === 0) {
						Swal.fire(
							'¡Atención!',
							'¡Debe ingresar un recibo válido para completar el registro!',
							'warning'
						);
						return;
					}
					this.loading = true;
					solicitudResource.store(this.solicitud).then(response => {
						//diversos mensajes en dependiendo de la respuesta
						if(response.resultado == 1)
						{
							Swal.fire(
								'¡Éxito!',
								response.mensaje + '<br>Su número de expediente es el: <strong>' + response.nroExpediente + '</strong>, del año ' + response.anio,
								'success'
							);
							this.$parent.tramite = 0;
							this.$parent.opcion = 1;
							this.$parent.boton=false;
						}
						else
						{
							this.$message({
								message: response.mensaje,
								type: 'error',
								duration: 5 * 1000
							});
						}
					})
					.catch(error => {
						console.log(error);
						this.$message({
							message: "Ocurrió un error inesperado",
							type: 'error',
							duration: 5 * 1000
						});
					}).finally(() => this.loading = false);
				}
				else return false;
			});
		},
		async verificaEntidad()
		{
			let valido = 0;
			this.$refs.solicitud.validateField('ruc', (error) => {
				if(error) valido++;
			});
			this.$refs.solicitud.validateField('razonSocial', (error) => {
				if(error) valido++;
			});
			if(valido > 0)
			{
				return;
			}
			else
			{
				//Hace la validación de ruc y razón social con el webservice
				this.loading = true;
				var consulta = {
					'ruc': this.solicitud.ruc,
					'razonSocial': this.solicitud.razonSocial
				};
				let me = this;
				entidadResource.validar(consulta)
				.then(response => {
					if(response.resultado == 1)
					{
						me.solicitud.personaJuridica_id = response.entidad.id;
						me.solicitud.razonSocial = response.entidad.razonSocial;
						me.deshabilitar = true;
						this.$message({
							message: "¡Entidad Validada!",
							type: 'success',
							duration: 5 * 1000
						});
					}
					else
					{
						this.$message({
							message: '¡Los datos ingresados no son correctos!',
							type: 'warning',
							duration: 5 * 1000
						});
					}
					me.loading = false;
				})
				.catch(error => {
					this.$message({
						message: "Ocurrió un error, por favor, vuelva a intentar más tarde",
						type: 'error',
						duration: 5 * 1000
					});
					me.loading = false;
				});
			}
		}
	},
	mounted(){
		this.getNombreProcedimiento();
		this.getDocumentos();
		this.getRequisitos();
	}
}
</script>

<style>
	#recibo .el-input-group__prepend {
		width: 45%;
	}
</style>
