import request from '@/utils/request';

class DocumentoResource {
  constructor(){
    this.uri = "documentos";
  }
  listar(){
    return request({
      url: this.uri + '/listar',
      method: 'get'
    });
  }
}

export { DocumentoResource as default };