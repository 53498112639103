<template>
  <!-- <div class="container"> -->

  <div style="width:auto; padding: 2px 0;" v-if="this.asistenciaDeHoy">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span v-if="this.total > 0"
          class="card-title"
        >Asistencia del dí­a {{ this.asistenciaHoy.fecha }} para el Área {{ this.datosJefe.nom_area }}</span>

        <el-button
          v-if="this.total > 0"
          size="mini"
          class="filter-item"
          style="float: right; margin-left: 10px;"
          type="primary"
          icon="icon-plus"
          @click="handleCerrarAsistencia()"
        >Cerrar asistencia</el-button>
        <!-- <el-button
          size="mini"
          class="filter-item"
          style="float: right; margin-left: 10px;"
          type="primary"
          icon="icon-printer"
        > Imprimir</el-button>-->
        <el-button
          v-if="this.total > 0"
          size="mini"
          class="filter-item"
          style="float: right; margin-left: 10px;"
          type="primary"
          icon="icon-refresh"
          @click="handleRefresh"
        >Actualizar</el-button>
      </div>
      <div>
        <el-table
          ref="TablaAsistenciaTrabajadores"
          :data="listAsistenciaDelDia"
          row-key="id"
          v-loading="listLoading"          
          height="450px"
          size="mini"
          border
          highlight-current-row
          style="width:100%;min-height:450px"
          :cell-style="{ padding: '0', heigth: '21px' }"
        >
          <el-table-column width="45">
            <template slot-scope="scope">
              <span>{{ scope.row.index }}</span>
            </template>
          </el-table-column>
          <el-table-column label="ID" width="50">
            <template slot-scope="scope">
              <span>{{ scope.row.cod_tobrero }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Apellidos y nombres">
            <template slot-scope="scope">
              <span>{{ scope.row.apenom }}</span>
            </template>
          </el-table-column>
          <el-table-column label="DNI" width="80" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.dni }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Modalidad" width="90" align="center">
            <template slot-scope="{ row }">
              <el-tag size="mini" :type="row.prestacion | statusPRM">{{ row.prestacion }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Marca" width="55" align="center">
            <template slot-scope="{ row }">
              <el-tag size="mini" :type="row.marca | statusMarca">{{ row.marca }}</el-tag>
              <!-- <span>{{ scope.row.marca }}</span> -->
            </template>
          </el-table-column>
          <el-table-column label="Justifica" width="88" align="center">
            <template slot-scope="{ row }">
              <!-- <el-tag size="mini" :type="row.marca | statusMarca">{{ row.marca }}</el-tag> -->
              <span>{{ row.numExpediente }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Fecha just." width="125" align="center">
            <template slot-scope="{ row }">
              <!-- <el-tag size="mini" :type="row.marca | statusMarca">{{ row.marca }}</el-tag> -->
              <span>{{ row.fecha_justifica | parseTime('{d}-{m}-{y} {h}:{i}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="A/C" width="45" align="center">
            <template slot-scope="{ row }">
              <el-tag size="mini" :type="row.cerrado | statusCerrado">{{ row.cerrado }}</el-tag>
              <!-- <span>{{ scope.row.cerrado }}</span> -->
            </template>
          </el-table-column>
          <el-table-column label="Cierre" width="125" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.fechacierre | parseTime('{d}-{m}-{y} {h}:{i}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="" width="90" align="center">
            <template slot-scope="scope">
              <el-button                
                size="mini"
                style="padding:3px 5px"
                type="success"                
                @click="handleAsistio(scope.row)"
              >A</el-button>
              <el-button
                size="mini"
                style="padding:3px 5px"
                type="danger"                
                @click="handleNoAsistio(scope.row)"
              >F</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </div>
    </el-card>
  </div>
</template>


<script>
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
import AsistenciaResource from "@/api/asistencia";
import TobreroResource from "@/api/tobrero";

const asistenciaResource = new AsistenciaResource();
const tobreroResource = new TobreroResource();

export default {
  name: "AsistenciaList",
  components: { Pagination },
  props: { auth: Object },
  filters: {
    statusPRM(status) {
      const statusMap = {
        PRESENCIAL: "success",
        REMOTO: "danger",
        MIXTO: "warning"
      };
      return statusMap[status];
    },
    statusMarca(status) {
      const statusMap = {
        A: "success",
        F: "danger"
      };
      return statusMap[status];
    },
    statusCerrado(status) {
      const statusMap = {
        A: "success",
        C: "danger"
      };
      return statusMap[status];
    },
    formatdate(fecha) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    }
  },
  data() {
    return {
      listLoading: true,
      asistenciaDeHoy: false,
      listQuery: {
        page: 1,
        limit: 15,
        buscar: "",
        criterio: "",
        id_jefearea: 0
      },
      nonAdminRoles: ["usuario"],
      currentUser_id: 0,
      currentAsistencia: {},
      // datosJefe: {
      //   cod_tobrero: "",
      //   cod_personal_empresa: "",
      //   dni: "",
      //   paterno: "",
      //   materno: "",
      //   nombre: "",
      //   areaxx_id: "",
      //   nom_area: "",
      //   cargo: "",
      //   trabajoxx: "",
      // },
      datosJefe: null,
      listAsistenciaDelDia: [], // almacena la lista de los ultimos movimientos del area origen
      asistenciaHoy: {},
      pagination: [],
      errorsForms: [],
      total: 0,
      vdata: {},
      vmeta: {}
    };
  },
  created() {
    this.getListTrabajadoresArea();
  },
  methods: {
    getListTrabajadoresArea() {
      this.asistenciaDeHoy = false;
      tobreroResource
        .getTobreroData(this.auth.cod_tobrero)
        .then(response => {
          this.datosJefe = response.data;          
          this.getList();
        })
        .catch(error => {
          console.log(error);
        });
    },

    async getList() {
      const { limit, page } = this.listQuery;
      this.listLoading = true;
      this.listQuery.id_jefearea = this.datosJefe.cod_tobrero;
      const { data, meta } = await asistenciaResource.list(this.listQuery);
      this.listAsistenciaDelDia = data;
      this.listAsistenciaDelDia.forEach((element, index) => {
        element["index"] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;

      if (this.total !== 0) {
        let mid = this.listAsistenciaDelDia[0].asistencia_id;
        this.getFechaAsistencia(mid);
      } else {
        this.$message({
          message: "No se encontró asistencia para el día de hoy. Asegúrese que no es sábado o domingo.",
          type: "info",
          duration: 1 * 3000
        });
      }
      this.listLoading = false;
      this.asistenciaDeHoy = true;
    },

    async getFechaAsistencia(id) {
      const { data } = await asistenciaResource.get(id);
      this.asistenciaHoy = data;
    },

    handleRefresh() {
      //this.listQuery.page = 1;
      this.getList();
    },

    handleAsistio(row) {
      let me = this;
      if (row.cerrado === "C") {
        this.$message({
          message: "Asistencia del área está cerrada",
          type: "info",
          duration: 1 * 1000
        });
        return false;
      }
      if (row.marca === "F") {
        me.currentAsistencia = {};
        me.currentAsistencia.id = row.id;
        me.currentAsistencia.marca = "A";
        //console.log('Asistencia id: ' . row.asistencia_id);
        me.listLoading = true;
        asistenciaResource
          .marca(row.id, { marca: me.currentAsistencia.marca })
          .then(response => {
            me.listLoading = false;
            this.$message({
              message: "Asistencia asignada correctamente a " + row.apenom,
              type: "success",
              duration: 1 * 1000
            });
            row.marca = "A";
            //me.handleRefresh();
          })
          .catch(error => {
            console.log(error);
            me.listLoading = false;
          });
      } else {
        this.$message({
          message: "Asistencia ya asignada " + row.marca,
          type: "warning",
          duration: 1 * 1000
        });
        return true;
      }
    },

    handleNoAsistio(row) {
      let me = this;
      if (row.cerrado === "C") {
        this.$message({
          message: "Asistencia del área está cerrada",
          type: "info",
          duration: 1 * 1000
        });
        return false;
      }
      if (row.marca === "A") {
        me.currentAsistencia = {};
        me.currentAsistencia.id = row.id;
        me.currentAsistencia.marca = "F";
        //console.log('Asistencia id: ' . row.asistencia_id);
        me.listLoading = true;
        asistenciaResource
          .marca(row.id, { marca: me.currentAsistencia.marca })
          .then(response => {
            me.listLoading = false;
            this.$message({
              message: "Inasistencia asignada correctamente a " + row.apenom,
              type: "success",
              duration: 1 * 1000
            });
            row.marca = "F";
            //me.handleRefresh();
          })
          .catch(error => {
            console.log(error);
            me.listLoading = false;
          });
      } else {
        this.$message({
          message: "Inasistencia ya asignada " + row.marca,
          type: "warning",
          duration: 1 * 1000
        });
        return true;
      }
    },

    handleCerrarAsistencia() {
      let me = this;
      if (this.asistenciaHoy.cerrado === "C") {
        this.$message({
          message: "Asistencia del área está cerrada",
          type: "info",
          duration: 1 * 1000
        });
        return false;
      }
      let asist = {};
      asist.id = this.asistenciaHoy.id; //me.listAsistenciaDelDia[0].asistencia_id;
      //console.log(asist.id);
      Swal.fire({
        icon: "warning",
        title: "Cerrar Asistencia de hoy <br>" + this.asistenciaHoy.fecha,
        html: "¿Desea cerrar la asistencia de " + this.datosJefe.nom_area + "?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        focusCancel: true,
        confirmButtonColor: "#d33"
      }).then(result => {
        if (result.value) {
          //delete confirm
          me.listLoading = true;
          asistenciaResource
            .cerrarAsistencia(asist.id)
            .then(response => {
              this.$message({
                message:
                  "Asistencia del área " +
                  this.datosJefe.nom_area +
                  " CERRADA correctamente",
                type: "success",
                duration: 1 * 1000
              });
              this.handleRefresh();
            })
            .catch(error => {
              console.log(error);
              me.listLoading = false;
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("Cancel");
        }
      });
    }
  },

  mounted() {
    //this.listarUsuarios(this.buscar, this.criterio, (this.tipoAccion = 0));
  }
};
</script>

<style type="text/css">
.swal2-container {
  z-index: 10000;
}

.el-form-item {
  margin-bottom: 5px !important;
}
</style>
